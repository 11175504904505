.Accordion {
  .icon {
    margin-right: 15px;
  }

  .accordion-header {
    display: flex;
    align-items: center;
    height: 17px;
    margin-bottom: 10px;
    cursor: pointer;
    width: 100%;

    &__open {
      .icon {
        transform: rotate(180deg);
      }
    }

    .body1 {
      font-weight: 600;
    }

    .title {
      flex: 1;
    }

    .wrapp {
      display: inline-grid;
      grid-template-columns: 1fr 1fr 1fr;
      flex: 1;

      .item {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .dark-gray {
          opacity: 0.6;
        }
      }
    }
  }

  .accordion-content {
    display: none;

    &__open {
      display: block;
    }
  }
}
