.logs {
    padding-bottom: 40px;

    .filters {
        display: flex;
        justify-content: row;
        flex-wrap: wrap;
        margin-bottom: 25px;
        margin-top: 50px;
        gap: 20px;

        input::-webkit-input-placeholder {
            color: rgba(0, 0, 0, 0.6);
            opacity: 0.8;
        }

        .MuiOutlinedInput-notchedOutline {
            border: 0
        }

        .searchEmploee {
            width: 202px;
            height: 32px;
            background: #FFFFFF;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0;
            margin-top: 5px;
        }

        .searchDate {
            width: 460px;
            height: 32px;
            margin-right: 0;
            margin-top: 5px;

            .span {
                position: absolute;
                top: 55px;
                text-align: center;
                width: 460px;
            }
        }

    }
}