@media only screen and (min-width : 1000px) {
  .Sort {
    margin: 20px 0;

    .container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        gap: 10px;

        .search {
            width: 325px;
            position: relative;
            margin-right: 20px;

            input {
                width: 100%;
                padding: 8px 35px 8px 12px;
            }

            svg {
                position: absolute;
                top: 8px;
                right: 12px;
            }
        }

        .DatePicker {
            margin-left: 20px;
            margin-right: 20px;

            .date-picker {
                width: 160px;
                margin: 0;
            }
        }

        input {
            width: 210px;
            margin-left: 20px;
        }

        input:first-child {
            margin-left: 0;
        }

        &-statistics {
            .date {
                margin-right: 20px;
            }

            .save {
                margin-left: auto;
                width: 210px;

                svg {
                    margin-left: 15px;
                }
            }
    

            Select {
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
                  0px 0px 1px rgba(0, 0, 0, 0.04);
                border-radius: 4px;
                height: 32px;
                width: 210px;
              
                
              
                &.white {
                  background: #fff;
                }
              
                &.gray {
                  background: #f0f1f3;
              
                  .Dropdown-content {
                    background: #f0f1f3;
                    max-height: 99px;
              
                    button {
                      padding: 8px 12px;
                    }
                  }
                }
              
                .Dropdown {
                  &-button {
                    text-align: left;
                    width: 100%;
              
                    .select {
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      padding: 8px 12px;
              
                      .placeholder {
                        color: rgba(66, 69, 76, 0.5);
                      }
              
                      span {
                        margin-right: 5px;
                      }
                    }
              
                    .placeholder-value {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    }
                  }
              
                  &-content {
                    top: calc(100% + 2px);
                    border-radius: 4px;
                    width: 100%;
                    max-height: 205px;
                    overflow: auto;
                    padding: 0;
              
                    button {
                      width: 100%;
                      text-align: left;
                      padding: 12px 20px;
                      display: flex;
                      align-items: center;
              
                      span.rectangle {
                        display: block;
                        margin-right: 10px;
                        width: 14px;
                        height: 14px;
                        border: 1px solid rgba(66, 69, 76, 0.2);
                        border-radius: 2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
              
                        .rectangle-icon {
                          display: none;
                        }
              
                        &.selected {
                          background: rgba(63, 115, 249, 1);
              
                          .rectangle-icon {
                            display: block;
                          }
                        }
                      }
                    }
                  }
                }
              }
              
        }
    }
}

Select {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    height: 32px;
    width: 210px;
  
    
  
    &.white {
      background: #fff;
    }
  
    &.gray {
      background: #f0f1f3;
  
      .Dropdown-content {
        background: #f0f1f3;
        max-height: 99px;
  
        button {
          padding: 8px 12px;
        }
      }
    }
  
    .Dropdown {
      &-button {
        text-align: left;
        width: 100%;
  
        .select {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 8px 12px;
  
          .placeholder {
            color: rgba(66, 69, 76, 0.5);
          }
  
          span {
            margin-right: 5px;
          }
        }
  
        .placeholder-value {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
  
      &-content {
        top: calc(100% + 2px);
        border-radius: 4px;
        width: 100%;
        max-height: 205px;
        overflow: auto;
        padding: 0;
  
        button {
          width: 100%;
          text-align: left;
          padding: 12px 20px;
          display: flex;
          align-items: center;
  
          span.rectangle {
            display: block;
            margin-right: 10px;
            width: 14px;
            height: 14px;
            border: 1px solid rgba(66, 69, 76, 0.2);
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
  
            .rectangle-icon {
              display: none;
            }
  
            &.selected {
              background: rgba(63, 115, 249, 1);
  
              .rectangle-icon {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width : 375px) {
  .Sort {
    margin: 20px 0;
    background: #fff;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background: #fff;
        // flex-direction: row;
        gap: 10px;

        .search {
            width: 288px;
            position: relative;
            margin-right: 20px;

            input {
                width: 100%;
                padding: 8px 35px 8px 12px;
            }

            svg {
                position: absolute;
                top: 8px;
                right: 12px;
            }
        }

        .DatePicker {
            font-size: 12px;
            background: #FFF;

            .date-picker {
                font-size: 12px;
                width: 100px;
                margin: 0;
                background: #F0F1F3;
            }
        }

        input {
            width: 288px;
            margin-left: 20px;
        }

        input:first-child {
            margin-left: 0;
            background: #F0F1F3;
            height: 32px;
            font-size: 12px;
        }

        &-statistics {
            .date {
                margin-right: 20px;
            }

            .save {
                margin-left: auto;
                width: 210px;

                svg {
                    margin-left: 15px;
                }
            }
    

            Select {
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
                  0px 0px 1px rgba(0, 0, 0, 0.04);
                border-radius: 4px;
                height: 32px;
                width: 210px;
              
                
              
                &.white {
                  background: #fff;
                }
              
                &.gray {
                  background: #f0f1f3;
              
                  .Dropdown-content {
                    background: #f0f1f3;
                    max-height: 99px;
              
                    button {
                      padding: 8px 12px;
                    }
                  }
                }
              
                .Dropdown {
                  &-button {
                    text-align: left;
                    width: 100%;
              
                    .select {
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      padding: 8px 12px;
              
                      .placeholder {
                        color: rgba(66, 69, 76, 0.5);
                      }
              
                      span {
                        margin-right: 5px;
                      }
                    }
              
                    .placeholder-value {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    }
                  }
              
                  &-content {
                    top: calc(100% + 2px);
                    border-radius: 4px;
                    width: 100%;
                    max-height: 205px;
                    overflow: auto;
                    padding: 0;
              
                    button {
                      width: 100%;
                      text-align: left;
                      padding: 12px 20px;
                      display: flex;
                      align-items: center;
              
                      span.rectangle {
                        display: block;
                        margin-right: 10px;
                        width: 14px;
                        height: 14px;
                        border: 1px solid rgba(66, 69, 76, 0.2);
                        border-radius: 2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
              
                        .rectangle-icon {
                          display: none;
                        }
              
                        &.selected {
                          background: rgba(63, 115, 249, 1);
              
                          .rectangle-icon {
                            display: block;
                          }
                        }
                      }
                    }
                  }
                }
              }
              
        }
    }
}

  Select {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    height: 32px;
    width: 210px;
  
    
  
    &.white {
      background: #fff;
    }
  
    &.gray {
      background: #f0f1f3;
  
      .Dropdown-content {
        background: #f0f1f3;
        max-height: 99px;
  
        button {
          padding: 8px 12px;
        }
      }
    }
  
    .Dropdown {
      &-button {
        text-align: left;
        width: 100%;
  
        .select {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 8px 12px;
  
          .placeholder {
            color: rgba(66, 69, 76, 0.5);
          }
  
          span {
            margin-right: 5px;
          }
        }
  
        .placeholder-value {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
  
      &-content {
        top: calc(100% + 2px);
        border-radius: 4px;
        width: 100%;
        max-height: 205px;
        overflow: auto;
        padding: 0;
  
        button {
          width: 100%;
          text-align: left;
          padding: 12px 20px;
          display: flex;
          align-items: center;
  
          span.rectangle {
            display: block;
            margin-right: 10px;
            width: 14px;
            height: 14px;
            border: 1px solid rgba(66, 69, 76, 0.2);
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
  
            .rectangle-icon {
              display: none;
            }
  
            &.selected {
              background: rgba(63, 115, 249, 1);
  
              .rectangle-icon {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width : 375px) and (max-width: 767px) {
  .Sort {
    margin: 20px 0;
    background: #fff;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background: #fff;
        // flex-direction: row;
        gap: 10px;

        .search {
            width: 343px;
            position: relative;
            margin-right: 20px;

            input {
                width: 100%;
                padding: 8px 35px 8px 12px;
            }

            svg {
                position: absolute;
                top: 8px;
                right: 12px;
            }
        }

        .DatePicker {
            font-size: 12px;
            background: #FFF;

            .date-picker {
                font-size: 12px;
                width: 128px;
                margin: 0;
                background: #F0F1F3;
            }
        }

        input {
            width: 343px;
            margin-left: 20px;
        }

        input:first-child {
            margin-left: 0;
            background: #F0F1F3;
            height: 32px;
            font-size: 12px;
        }

        &-statistics {
            .date {
                margin-right: 20px;
            }

            .save {
                margin-left: auto;
                width: 210px;

                svg {
                    margin-left: 15px;
                }
            }
    

            Select {
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
                  0px 0px 1px rgba(0, 0, 0, 0.04);
                border-radius: 4px;
                height: 32px;
                width: 210px;
              
                
              
                &.white {
                  background: #fff;
                }
              
                &.gray {
                  background: #f0f1f3;
              
                  .Dropdown-content {
                    background: #f0f1f3;
                    max-height: 99px;
              
                    button {
                      padding: 8px 12px;
                    }
                  }
                }
              
                .Dropdown {
                  &-button {
                    text-align: left;
                    width: 100%;
              
                    .select {
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      padding: 8px 12px;
              
                      .placeholder {
                        color: rgba(66, 69, 76, 0.5);
                      }
              
                      span {
                        margin-right: 5px;
                      }
                    }
              
                    .placeholder-value {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    }
                  }
              
                  &-content {
                    top: calc(100% + 2px);
                    border-radius: 4px;
                    width: 100%;
                    max-height: 205px;
                    overflow: auto;
                    padding: 0;
              
                    button {
                      width: 100%;
                      text-align: left;
                      padding: 12px 20px;
                      display: flex;
                      align-items: center;
              
                      span.rectangle {
                        display: block;
                        margin-right: 10px;
                        width: 14px;
                        height: 14px;
                        border: 1px solid rgba(66, 69, 76, 0.2);
                        border-radius: 2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
              
                        .rectangle-icon {
                          display: none;
                        }
              
                        &.selected {
                          background: rgba(63, 115, 249, 1);
              
                          .rectangle-icon {
                            display: block;
                          }
                        }
                      }
                    }
                  }
                }
              }
              
        }
    }
}

  Select {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    height: 32px;
    width: 210px;
  
    
  
    &.white {
      background: #fff;
    }
  
    &.gray {
      background: #f0f1f3;
  
      .Dropdown-content {
        background: #f0f1f3;
        max-height: 99px;
  
        button {
          padding: 8px 12px;
        }
      }
    }
  
    .Dropdown {
      &-button {
        text-align: left;
        width: 100%;
  
        .select {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 8px 12px;
  
          .placeholder {
            color: rgba(66, 69, 76, 0.5);
          }
  
          span {
            margin-right: 5px;
          }
        }
  
        .placeholder-value {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
  
      &-content {
        top: calc(100% + 2px);
        border-radius: 4px;
        width: 100%;
        max-height: 205px;
        overflow: auto;
        padding: 0;
  
        button {
          width: 100%;
          text-align: left;
          padding: 12px 20px;
          display: flex;
          align-items: center;
  
          span.rectangle {
            display: block;
            margin-right: 10px;
            width: 14px;
            height: 14px;
            border: 1px solid rgba(66, 69, 76, 0.2);
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
  
            .rectangle-icon {
              display: none;
            }
  
            &.selected {
              background: rgba(63, 115, 249, 1);
  
              .rectangle-icon {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width : 768px) and (max-width: 1000px){
  .Sort {
    margin: 20px 0;

    .container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 704px;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 10px;
        background: #f0f1f3;
        position: relative;

        .search {
            width: 200px;
            position: relative;
            margin-right: 20px;

            input {
                width: 200px;
                padding: 8px 35px 8px 12px;
            }

            svg {
                position: absolute;
                top: 8px;
                right: 12px;
            }
        }

        .DatePicker {
            // margin-left: 20px;
            // margin-right: 20px;
            background: #f0f1f3;

            .date-picker {
              font-size: 12px;
                width: 160px;
                margin: 0;
                background: #fff;
            }
        }

        input {
            width: 210px;
            margin-left: 20px;
        }

        input:first-child {
            margin-left: 0;
            background: #fff;
        }

        &-statistics {
            .date {
                margin-right: 20px;
            }

            .save {
                margin-left: auto;
                width: 210px;

                svg {
                    margin-left: 15px;
                }
            }
    

            Select {
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
                  0px 0px 1px rgba(0, 0, 0, 0.04);
                border-radius: 4px;
                height: 32px;
                width: 210px;
              
                
              
                &.white {
                  background: #fff;
                }
              
                &.gray {
                  background: #f0f1f3;
              
                  .Dropdown-content {
                    background: #f0f1f3;
                    max-height: 99px;
              
                    button {
                      padding: 8px 12px;
                    }
                  }
                }
              
                .Dropdown {
                  &-button {
                    text-align: left;
                    width: 100%;
              
                    .select {
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      padding: 8px 12px;
              
                      .placeholder {
                        color: rgba(66, 69, 76, 0.5);
                      }
              
                      span {
                        margin-right: 5px;
                      }
                    }
              
                    .placeholder-value {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    }
                  }
              
                  &-content {
                    top: calc(100% + 2px);
                    border-radius: 4px;
                    width: 100%;
                    max-height: 205px;
                    overflow: auto;
                    padding: 0;
              
                    button {
                      width: 100%;
                      text-align: left;
                      padding: 12px 20px;
                      display: flex;
                      align-items: center;
              
                      span.rectangle {
                        display: block;
                        margin-right: 10px;
                        width: 14px;
                        height: 14px;
                        border: 1px solid rgba(66, 69, 76, 0.2);
                        border-radius: 2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
              
                        .rectangle-icon {
                          display: none;
                        }
              
                        &.selected {
                          background: rgba(63, 115, 249, 1);
              
                          .rectangle-icon {
                            display: block;
                          }
                        }
                      }
                    }
                  }
                }
              }
              
        }
    }
}

  Select {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    height: 32px;
    width: 210px;
  
    
  
    &.white {
      background: #fff;
    }
  
    &.gray {
      background: #f0f1f3;
  
      .Dropdown-content {
        background: #f0f1f3;
        max-height: 99px;
  
        button {
          padding: 8px 12px;
        }
      }
    }
  
    .Dropdown {
      &-button {
        text-align: left;
        width: 100%;
  
        .select {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 8px 12px;
  
          .placeholder {
            color: rgba(66, 69, 76, 0.5);
          }
  
          span {
            margin-right: 5px;
          }
        }
  
        .placeholder-value {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
  
      &-content {
        top: calc(100% + 2px);
        border-radius: 4px;
        width: 100%;
        max-height: 205px;
        overflow: auto;
        padding: 0;
  
        button {
          width: 100%;
          text-align: left;
          padding: 12px 20px;
          display: flex;
          align-items: center;
  
          span.rectangle {
            display: block;
            margin-right: 10px;
            width: 14px;
            height: 14px;
            border: 1px solid rgba(66, 69, 76, 0.2);
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
  
            .rectangle-icon {
              display: none;
            }
  
            &.selected {
              background: rgba(63, 115, 249, 1);
  
              .rectangle-icon {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}