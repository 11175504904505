@function calc-between-width($width-start, $value-start, $width-end, $value-end)
 { @return calc(#{$value-start} * 1px + (#{$value-end} - #{$value-start}) * (100vw - #{$width-start} * 1px) / (#{$width-end} - #{$width-start}))}
.AdminMenu {
  position: fixed;
  background-color: #FFFFFF;
  width: 190px;
  height: 100vh;
  z-index: 10000;

  .AdminLogo {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 21.93px;
    line-height: 21px;
    font-size: 12px;
    color: #42454c;
    .logo {
      width: 126.14px;
      height: 21.94px;
    }
  }

  .adminAccount {
    margin-left: 20.96px;
    position: fixed;
    bottom: 40px;

    .account {
      display: flex;
      flex-direction: column;

      .info {
        display: flex;
        flex-direction: column;
      }

      .name {
        height: auto;
        font-size: 13.16px;
        margin-bottom: 5px;
        //height: calc-between-width(1600, 25, 1920, 50);
        width: 173px
      }

      .role {
        height: 11px;
        font-size: 13.16px;
        color: #42454C;
        opacity: 70%
      }

      .settings {
        margin-top: 10px;
        margin-left: 5px;
      }
    }
    .logout{
      margin-top: 10px;
    }
  }

  .admin-menu-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 11.93px;
    margin: 80px 10px 20px 0px;

    a {
      color: #42454c;
      font-size: 13.16px;
      width: 133.81px;
      height: 24.13px;
      position: relative;
      padding-left: 10px;
      padding-top: 5px;

      &.active {
        border-radius: 4px;
        background: #DFE8FF;
        color: #3F73F9;
      }
    }

    a:last-child {
      margin-right: 0;
    }
  }
}