*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

/* body {
  min-width: 1170px;
} */

@media only screen and (min-width: 1000px) {
  .first {
    width: 1400px;
    padding: 10px;
    height: 100%;
  }

  .wraper {
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 10px;
    border: 2px solid blue;
    border-radius: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .first {
    width: 375px;
    /* padding: 10px; */
    height: 100%;
  }
  .wraper {
    max-width: 375;
    height: 100%;
    /* margin: 0 auto; */
    /* padding: 10px; */
    /* border: 2px solid blue;
    border-radius: 20px; */
  }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .first {
    width: 704px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: 100%;
    border-radius: 10px;
    /* margin: 0 auto; */
  }
  .wraper {
    max-width: 704px;
    height: 100%;
    /* padding: 10px; */
    border: 2px solid blue;
    border-radius: 10px;
  }
}

.label2 {
  position: absolute;
}

.radio__check {
  display: flex;
  flex-direction: column;
}

.grid {
  height: 100%;
  overflow: hidden;
  padding-right: 20px;
}

.bannerDropzone {
  border-width: 2px;
  border-style: solid;
  border-color: #f0f1f3;
  height: 320px;
}

.panel {
  background-color: white;
  height: 100%;
  max-width: 100%;
}

/* @media (max-width: 1280px) {
  .first {
    width: 900px;
  }
} */

/* @media (max-width: 900px) {
  .first {
    width: 400px;
  }
} */

.file_upload {
  display: inline-block;
  padding: 0 12px;
  height: 36px;
  line-height: 36px;
  color: aliceblue;
  background-color: blue;
  cursor: pointer;
  margin-top: 50px;
}

.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  padding: 20px;
  cursor: pointer;
  height: 80px;
  width: 400px;
  background: #eff4ff;
  border: 1px dashed #3f73f9;
  outline: none;
}

.dropzoneReject {
  border: 3px dashed red;
}

.dropzoneAccept {
  border: 3px dashed green;
}

.dropzone1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  padding: 20px;
  cursor: pointer;
  height: 130px;
  width: 100%;
  background: #eff4ff;
  border: 1px dashed #3f73f9;
  outline: none;
  &.aud {
    height: 125px;
  }
}

.dropzone2 {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 223px;
  width: 228px;
  background: #eff4ff;
  border: 1px dashed #3f73f9;
  outline: none;
}

.carousel {
  height: 223px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 980px;
}

.carouselItem {
  height: 223px;
  width: 134px;
  border: 3px dotted gray;
}

.dropzoneReject1 {
  border: 3px dashed red;
  height: 150px;
}

.dropzoneAccept1 {
  border: 3px dashed green;
  height: 150px;
}

.rec.rec-arrow {
  border-radius: 20;
  color: #9c9ea3;
  height: 0;
  width: 0;
  margin-bottom: 50px;
}

.rec.rec-arrow:hover {
  border-radius: 50%;
}
