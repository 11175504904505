@function calc-between-width($width-start, $value-start, $width-end, $value-end)
 { @return calc(#{$value-start} * 1px + (#{$value-end} - #{$value-start}) * (100vw - #{$width-start} * 1px) / (#{$width-end} - #{$width-start}))}
.AdminMainLayout {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    background: #f0f1f3;
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.03),
      0px 6.4px 14.4px rgba(0, 0, 0, 0.07);
    // overflow-y: auto;
    overflow: overlay;
    //overflow-x: hidden;
  
    &-content {
      background: #f0f1f3;
      padding: 30px;
      width: 100vw;
      margin-left: 170px;
    }
  }
  