.Profile {
  .nav {
    margin-top: 40px;
    background: #f9f9f9;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 2px solid rgba(66, 69, 76, 0.1);
    padding: 0 60px;

    a {
      color: rgba(66, 69, 76, 0.6);
      font-weight: 400;
      font-size: 18px;
      margin-right: 40px;
      position: relative;

      &.active {
        color: #42454c;
      }

      &.active::before {
        content: "";
        border-bottom: 3px solid #3f73f9;
        position: absolute;
        bottom: -43px;
        left: 0;
        width: 100%;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .CardWhite {
    border-radius: 0 0 16px 16px;
    padding: 40px 60px 60px 60px;
  }
}

@media screen and (max-width : 375px) {
  .Profile {
    .Typography {
      &.h1 {
        &.dark-gray {
          margin-top: 25px;
        }
      }
    }

    .nav {
      display: flex;
      width: 288px;
      height: 45px;
      justify-content: center;
      align-items: center;
      border-radius: 4px 4px 0px 0px;
      border: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
      background: var(--colors-f-9-f-9-f-9, #F9F9F9);
      margin-top: 12px;

      a {
        margin-right: 20px;
        white-space: nowrap;
        color: var(--colors-42454-c, #42454C);
        text-align: center;
        font-size: 11.755px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &.active::before {
          bottom: -16px;
        }
      }
    }

    .CardWhite {
      display: flex;
      align-items: center;
      width: 288px;
      //height: 355px;
      padding: 20.152px 13.434px;
      border-radius: 0px 0px 4px 4px;
      border-right: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
      border-bottom: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
      border-left: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
      background: var(--colors-f-9-f-9-f-9, #F9F9F9);


      .BankAudits {
        form {
          .Typography {
            &.h1 {
              &.dark-gray {
                display: none;
              }
            }
          }
        }

        .actions {
          margin-top: 0;
          display: flex;
          width: 261px;
          height: 27px;
          justify-content: flex-end;
          align-items: center;
          flex-shrink: 0;

          button {
            border-radius: 4px;
            background: var(--colors-3-f-73-f-9, #3F73F9);
            width: 261px;
            height: 27px;
          }
        }

        .rows {
          margin-top: 0;

          .row {
            .items {
              flex-direction: column;
              align-items: baseline;

              .input-data {
                min-width: 0;
                width: 261px;
                background: #fff;
                height: 27px;
                margin-bottom: 30px;

                .copyIcon {
                  top: 4px;
                  right: 27px;
                }
              }

              .css-1wc848c-MuiFormHelperText-root.Mui-error {
                width: 261px;
                white-space: nowrap;
              }

              .css-1wc848c-MuiFormHelperText-root {
                width: 261px;
              }

              .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root {
                overflow: visible;
              }

              .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
                height: 27px;
                width: 261px;
                border-radius: 4px;
                background: #F0F1F3;
                box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

                input {
                  font-size: 14px;
                  padding: 0;
                  padding-left: 10px;
                  box-shadow: none;
                }

                fieldset {
                  width: 261px;
                  border: none;
                }
              }

              .css-1v4ccyo {
                height: 27px;
                width: 261px;
                border-radius: 4px;
                background: #F0F1F3;
                box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

                input {
                  font-size: 14px;
                  padding: 0;
                  padding-left: 10px;
                  box-shadow: none;
                }

                fieldset {
                  width: 261px;
                  border: none;
                }
              }
            }
          }
        }

      }

      .ContactDetails {
        form {
          .Typography {
            &.h1 {
              &.dark-gray {
                display: none;
              }
            }
          }
        }

        .actions {
          margin-top: 0;
          display: flex;
          width: 261px;
          height: 27px;
          justify-content: flex-end;
          align-items: center;
          flex-shrink: 0;

          button {
            border-radius: 4px;
            background: var(--colors-3-f-73-f-9, #3F73F9);
            width: 261px;
            height: 27px;
          }
        }

        .rows {
          margin-top: 0;

          .row {
            .items {
              flex-direction: column;

              .input-data {
                width: 261px;

                .copyIcon {
                  top: 4px;
                  right: 27px;
                }
              }

              .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root {
                overflow: visible;
              }

              .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
                height: 27px;
                width: 261px;
                border-radius: 4px;
                background: #F0F1F3;
                box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

                input {
                  font-size: 14px;
                  padding: 0;
                  padding-left: 10px;
                  box-shadow: none;
                }

                fieldset {
                  width: 261px;
                  border: none;
                }
              }

              .css-1v4ccyo {
                height: 27px;
                width: 261px;
                border-radius: 4px;
                background: #F0F1F3;
                box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

                input {
                  font-size: 14px;
                  padding: 0;
                  padding-left: 10px;
                  box-shadow: none;
                }

                fieldset {
                  width: 261px;
                  border: none;
                }
              }
            }
          }
        }

      }
    }
  }
}

@media screen and (min-width:375px) and (max-width : 768px) {
  .Profile {
    .Typography {
      &.h1 {
        font-weight: bold;
        font-size: 16px;

        &.dark-gray {
          margin-top: 25px;
        }
      }
    }

    .nav {
      display: flex;
      width: 343px;
      height: 54px;
      justify-content: center;
      align-items: center;
      border-radius: 4px 4px 0px 0px;
      border: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
      background: var(--colors-f-9-f-9-f-9, #F9F9F9);
      margin-top: 12px;

      a {
        margin-right: 20px;
        white-space: nowrap;
        color: var(--colors-42454-c, #42454C);
        text-align: center;
        font-size: 11.755px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &.active::before {
          bottom: -19px;
        }
      }
    }

    .CardWhite {
      display: flex;
      align-items: center;
      width: 343px;
      //height: 355px;
      padding: 20.152px 13.434px;
      border-radius: 0px 0px 4px 4px;
      border-right: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
      border-bottom: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
      border-left: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
      background: var(--colors-f-9-f-9-f-9, #F9F9F9);


      .BankAudits {
        form {
          .Typography {
            &.h1 {
              &.dark-gray {
                display: none;
              }
            }
          }
        }

        .actions {
          margin-top: 0;
          display: flex;
          width: 311px;
          height: 32px;
          justify-content: flex-end;
          align-items: center;
          flex-shrink: 0;

          button {
            border-radius: 4px;
            background: var(--colors-3-f-73-f-9, #3F73F9);
            width: 311px;
            height: 32px;
          }
        }

        .rows {
          margin-top: 0;

          .row {
            .items {
              flex-direction: column;
              align-items: baseline;

              .input-data {
                min-width: 0;
                width: 311px;
                background: #fff;
                height: 32px;
                margin-bottom: 30px;

                .copyIcon {
                  top: 4px;
                  right: 32px;
                }
              }

              .css-1wc848c-MuiFormHelperText-root.Mui-error {
                width: 311px;
                white-space: nowrap;
              }

              .css-1wc848c-MuiFormHelperText-root {
                width: 311px;
              }

              .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root {
                overflow: visible;
              }

              .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
                height: 32px;
                width: 311px;
                border-radius: 4px;
                background: #F0F1F3;
                box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

                input {
                  font-size: 14px;
                  padding: 0;
                  padding-left: 10px;
                  box-shadow: none;
                }

                fieldset {
                  width: 311px;
                  border: none;
                }
              }

              .css-1v4ccyo {
                height: 32px;
                width: 311px;
                border-radius: 4px;
                background: #F0F1F3;
                box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

                input {
                  font-size: 14px;
                  padding: 0;
                  padding-left: 10px;
                  box-shadow: none;
                }

                fieldset {
                  width: 311px;
                  border: none;
                }
              }
            }
          }
        }

      }

      .ContactDetails {
        form {
          .Typography {
            &.h1 {
              &.dark-gray {
                display: none;
              }
            }
          }
        }

        .actions {
          margin-top: 0;
          display: flex;
          width: 311px;
          height: 32px;
          justify-content: flex-end;
          align-items: center;
          flex-shrink: 0;

          button {
            border-radius: 4px;
            background: var(--colors-3-f-73-f-9, #3F73F9);
            width: 311px;
            height: 32px;
          }
        }

        .rows {
          margin-top: 0;

          .row {
            .items {
              flex-direction: column;

              .input-data {
                width: 311px;

                .copyIcon {
                  top: 4px;
                  right: 32px;
                }
              }

              .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root {
                overflow: visible;
              }

              .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
                height: 32px;
                width: 311px;
                border-radius: 4px;
                background: #F0F1F3;
                box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

                input {
                  font-size: 14px;
                  padding: 0;
                  padding-left: 10px;
                  box-shadow: none;
                }

                fieldset {
                  width: 311px;
                  border: none;
                }
              }

              .css-1v4ccyo {
                height: 32px;
                width: 311px;
                border-radius: 4px;
                background: #F0F1F3;
                box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

                input {
                  font-size: 14px;
                  padding: 0;
                  padding-left: 10px;
                  box-shadow: none;
                }

                fieldset {
                  width: 311px;
                  border: none;
                }
              }
            }
          }
        }

      }
    }
  }
}

@media screen and (min-width:768px) and (max-width : 1000px) {
  .Profile {
    .Typography {
      &.h1 {
        font-weight: bold;

        &.dark-gray {
          margin-top: 25px;
        }
      }
    }

    .nav {
      display: flex;
      width: 704px;
      height: 54px;
      justify-content: center;
      align-items: center;
      border-radius: 4px 4px 0px 0px;
      border: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
      background: var(--colors-f-9-f-9-f-9, #F9F9F9);
      margin-top: 12px;

      a {
        margin-right: 20px;
        white-space: nowrap;
        color: var(--colors-42454-c, #42454C);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &.active::before {
          bottom: -19px;
        }
      }
    }

    .CardWhite {
      width: 704px;
      //height: 332px;
      display: flex;
      width: 704px;
      padding: 24px 30px 36px 30px;
      align-items: flex-start;
      border-radius: 0px 0px 4px 4px;
      border-right: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
      border-bottom: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
      border-left: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
      background: var(--colors-f-9-f-9-f-9, #F9F9F9);


      .BankAudits {
        form {
          .Typography {
            &.h1 {
              &.dark-gray {
                display: none;
              }
            }
          }
        }

        .actions {
          margin-top: 0;
          display: flex;
          width: 644px;
          height: 32px;
          justify-content: flex-end;
          align-items: center;
          flex-shrink: 0;

          button {
            border-radius: 4px;
            background: var(--colors-3-f-73-f-9, #3F73F9);
            width: 200px;
            height: 32px;
          }
        }

        .rows {
          display: flex;
          gap: 24px;
          margin-top: 0;

          .row {
            .items {
              flex-direction: column;
              align-items: baseline;

              .input-data {
                min-width: 0;
                width: 300px;
                background: #fff;
                height: 32px;
                margin-bottom: 30px;

                .copyIcon {
                  top: 4px;
                  right: 32px;
                }
              }

              .css-1wc848c-MuiFormHelperText-root.Mui-error {
                width: 300px;
                white-space: nowrap;
              }

              .css-1wc848c-MuiFormHelperText-root {
                width: 300px;
              }

              .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root {
                overflow: visible;
              }

              .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
                height: 32px;
                width: 300px;
                border-radius: 4px;
                background: #F0F1F3;
                box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

                input {
                  font-size: 14px;
                  padding: 0;
                  padding-left: 10px;
                  box-shadow: none;
                }

                fieldset {
                  width: 300px;
                  border: none;
                }
              }

              .css-1v4ccyo {
                height: 32px;
                width: 300px;
                border-radius: 4px;
                background: #F0F1F3;
                box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

                input {
                  font-size: 14px;
                  padding: 0;
                  padding-left: 10px;
                  box-shadow: none;
                }

                fieldset {
                  width: 300px;
                  border: none;
                }
              }
            }
          }
        }

      }

      .ContactDetails {
        form {
          .Typography {
            &.h1 {
              &.dark-gray {
                display: none;
              }
            }
          }
        }

        .actions {
          margin-top: 0;
          display: flex;
          width: 644px;
          height: 32px;
          justify-content: flex-end;
          align-items: center;
          flex-shrink: 0;

          button {
            border-radius: 4px;
            background: var(--colors-3-f-73-f-9, #3F73F9);
            width: 200px;
            height: 32px;
          }
        }

        .rows {
          margin-top: 0;

          .row {
            .items {
              flex-direction: column;

              .input-data {
                width: 311px;

                .copyIcon {
                  top: 4px;
                  right: 32px;
                }
              }

              .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root {
                overflow: visible;
              }

              .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
                height: 32px;
                width: 311px;
                border-radius: 4px;
                background: #F0F1F3;
                box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

                input {
                  font-size: 14px;
                  padding: 0;
                  padding-left: 10px;
                  box-shadow: none;
                }

                fieldset {
                  width: 311px;
                  border: none;
                }
              }

              .css-1v4ccyo {
                height: 32px;
                width: 311px;
                border-radius: 4px;
                background: #F0F1F3;
                box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

                input {
                  font-size: 14px;
                  padding: 0;
                  padding-left: 10px;
                  box-shadow: none;
                }

                fieldset {
                  width: 311px;
                  border: none;
                }
              }
            }
          }
        }

      }
    }
  }
}