.Card {
	overflow: hidden;

	.content {
		display: flex;
		padding: 40px 40px 40px 60px;
	}

	.settingsButton {
		width: 40px;
		cursor: pointer;
		outline: none;
		border: none;
		background: linear-gradient(180deg, #6b58e0 0%, #3f73f9 100%);
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
		margin-right: 20px;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
	}

	&.NOT-CHOSEN {
		background: #e7e1e1;
	}

	.Segment {
		background: #ffffff;
		box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
			0px 0px 1px rgba(0, 0, 0, 0.04);
		border-radius: 24px;
		overflow: hidden;

		.segment-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;
			padding: 20px 25px;
			border-bottom: 1px solid rgba(66, 69, 76, 0.1);

			.column {
				display: flex;
				align-items: center;

				.segment-icon {
					margin-right: 20px;

					&__open {
						transform: rotate(180deg);
						margin-right: 20px;
					}
				}

				.blue {
					margin-left: 10px;
				}
			}
		}

		.segment-content {
			padding: 30px;

			.column {
				.client {
					display: flex;
					align-items: flex-end;
					margin-bottom: 10px;

					.span {
						margin: 0 10px;
					}
				}

				.category {
					min-width: 480px;
					display: flex;
					align-items: center;
					flex-wrap: wrap;

					.buttons {
						display: flex;
						flex-wrap: wrap;
						width: 790px;

						.catalog {
							margin: 0 10px 10px 0;
							background: rgba(63, 115, 249, 0.1);
							border-radius: 4px;
							border: 1px solid #3f73f9;
							height: 32px;
							min-width: 80px;
							font-weight: 500;
							font-size: 12px;
							padding: 8px 10px;
							display: flex;
							align-items: center;
							justify-content: center;

							.Typography {
								font-weight: 400;
								color: #3f73f9;
							}
						}

						.catalog2 {
							margin: 0 10px 10px 0;
							background: rgba(249, 63, 125, 0.1);
							border-radius: 4px;
							border: 1px solid #ee2748;
							height: 32px;
							min-width: 80px;
							font-weight: 500;
							font-size: 12px;
							padding: 8px 10px;
							display: flex;
							align-items: center;
							justify-content: center;

							.Typography {
								font-weight: 400;
								color: #ee2748;
							}
						}
					}

					.cirlce-content {
						.SegIc {
							color: linear-gradient(180deg, #6b58e0 0%, #3f73f9 100%);
						}
					}

				}

				.title {
					letter-spacing: 0.04em;
				}

				.css-p0rm37 {
					top: -11px !important;
					color: rgba(66, 69, 76, 0.50) !important;

					font-size: 14px !important;
					font-style: normal !important;
					font-weight: 400 !important;
					line-height: normal !important;
				}

				.css-p0rm37.Mui-focused {
					color: rgba(66, 69, 76, 0.50) !important;
					top: -7px !important;
				}

				.items {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					margin-top: 10px;

					.BudgetManagement:last-child {
						margin-right: 0;
					}

					.statistics {
						background: none;
						width: 80px;
					}
				}

				.Line {
					margin: 0 40px;
				}
			}
		}
	}

}

@media screen and (max-width : 375px) {
	.socialItem {
		padding-top: 0 !important;
	}

	.gridItem {
		padding-top: 18px !important;
		margin-bottom: 0 !important;
	}

	.Card {
		.Segment {
			width: 288px;
			border-radius: 0;
			border: none;
		}

		.circle {
			left: 15px;
		}

		.id {
			display: none;
		}

		.buttons {
			margin-top: 20px;
			width: 287px !important;
		}

		.Line {
			display: none;
		}

		.Typography {
			opacity: 1;
		}

		&.NOT-CHOSEN {
			width: 288px;
			border: none;
			border-radius: 10.076px;
			background: #FFF;
			box-shadow: 0px 0px 0.8396501541137695px 0px rgba(0, 0, 0, 0.04), 0px 1.679300308227539px 5.037900924682617px 0px rgba(0, 0, 0, 0.04), 0px 13.434402465820312px 20.15160369873047px 0px rgba(0, 0, 0, 0.06);
		}

		&.CHOSEN {
			width: 288px;
			border-radius: 10.076px;
			box-shadow: 0px 0px 0.8396501541137695px 0px rgba(0, 0, 0, 0.04), 0px 1.679300308227539px 5.037900924682617px 0px rgba(0, 0, 0, 0.04), 0px 13.434402465820312px 20.15160369873047px 0px rgba(0, 0, 0, 0.06);
			background: linear-gradient(180deg, #6b58e0 0%, #3f73f9 100%) border-box;
		}

		.segment-icon {
			margin-left: 5px !important;
			margin-right: 5px !important;
		}

		.segment-icon__open {
			margin-left: 5px !important;
			margin-right: 5px !important;
		}

		.segment-header {
			padding: 0 !important;
		}

		.title {
			margin-right: 20px;
		}

		.settings {
			padding-left: 10px;
			padding-top: 5px;
			padding-right: 10px;
		}

		.hide {
			display: none;
		}
	}
}

@media screen and (min-width:375px) and (max-width : 768px) {
	.socialItem {
		padding-top: 0 !important;
	}

	.gridItem {
		padding-top: 18px !important;
		margin-bottom: 0 !important;
	}

	.Card {
		.Segment {
			width: 342px;
			border-radius: 0;
			border: none;

			.segment-content {
				.column {
					.category {
						padding-left: 20px;
					}
				}
			}
		}

		.circle {
			left: 15px;
		}

		.id {
			display: none;
		}

		.buttons {
			margin-top: 20px;
			width: 287px !important;
		}

		.Line {
			display: none;
		}

		.Typography {
			opacity: 1;
		}

		&.NOT-CHOSEN {
			width: 342px;
			border: none;
			border-radius: 10.076px;
			background: #FFF;
			box-shadow: 0px 0px 0.8396501541137695px 0px rgba(0, 0, 0, 0.04), 0px 1.679300308227539px 5.037900924682617px 0px rgba(0, 0, 0, 0.04), 0px 13.434402465820312px 20.15160369873047px 0px rgba(0, 0, 0, 0.06);
		}

		&.CHOSEN {
			width: 342px;
			border-radius: 10.076px;
			box-shadow: 0px 0px 0.8396501541137695px 0px rgba(0, 0, 0, 0.04), 0px 1.679300308227539px 5.037900924682617px 0px rgba(0, 0, 0, 0.04), 0px 13.434402465820312px 20.15160369873047px 0px rgba(0, 0, 0, 0.06);
			background: linear-gradient(180deg, #6b58e0 0%, #3f73f9 100%) border-box;
		}

		.segment-icon {
			margin-left: 5px !important;
			margin-right: 5px !important;
		}

		.segment-icon__open {
			margin-left: 5px !important;
			margin-right: 5px !important;
		}

		.segment-header {
			padding: 0 !important;
		}

		.title {
			margin-right: 20px;
		}

		.settings {
			padding-left: 10px;
			padding-top: 5px;
			padding-right: 10px;
		}

		.hide {
			display: none;
		}
	}
}

@media screen and (min-width:768px) and (max-width : 1000px) {
	.css-13xfq8m-MuiTabPanel-root:has(.audience) {
		margin: 0 !important;
	}

	.css-19kzrtu:has(.audience) {
		margin: 0 !important;
	}

	.Card {
		&.NOT-CHOSEN {
			width: 600px;
			border: none;
			border-radius: 10.076px;
			background: #FFF;
			box-shadow: 0px 0px 0.8396501541137695px 0px rgba(0, 0, 0, 0.04), 0px 1.679300308227539px 5.037900924682617px 0px rgba(0, 0, 0, 0.04), 0px 13.434402465820312px 20.15160369873047px 0px rgba(0, 0, 0, 0.06);
		}

		&.CHOSEN {
			width: 600px;
			border-radius: 10.076px;
			box-shadow: 0px 0px 0.8396501541137695px 0px rgba(0, 0, 0, 0.04), 0px 1.679300308227539px 5.037900924682617px 0px rgba(0, 0, 0, 0.04), 0px 13.434402465820312px 20.15160369873047px 0px rgba(0, 0, 0, 0.06);
			background: linear-gradient(180deg, #6b58e0 0%, #3f73f9 100%) border-box;
		}

		.Segment {
			width: 600px;
			border-radius: 10.076px;

			.segment-content {
				.column {
					.category {
						flex-wrap: nowrap;
					}
				}
			}

			.id {
				display: none;
			}

			.Line {
				display: none;
			}

			.circle {
				margin-right: 37px;
			}

			.Typography {
				&.body1 {
					font-size: 14px;
					font-weight: normal;
					opacity: 1;
				}
			}
		}

	}
}