.css-1xhj18k {
  margin: 20px 0;
}

.td {
  .date-picker {
    width: 145px;
  }
}

.inputRange {
  .DatePicker {
    .date-picker {
      width: 216px;
      height: 56px;
      box-shadow: none;
      text-align: center;
      font: inherit;
      letter-spacing: inherit;
      color: currentColor;
      border: 0;
    }
    
    .css-ibnnmb{
      margin-left: 0;
      margin-right: 0;
    }

    .date-picker::placeholder {
      color: rgba(0, 0, 0, 0.6);
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.4375em;
      letter-spacing: 0.00938em;
      padding: 0;
      display: block;
      white-space: nowrap;
    }

    button {
      display: none;
    }
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.23);
  }
}