@import "_variables.scss";

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;700&display=swap");

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: #42454c;
  font-family: "Lato", sans-serif;
  font-size: 14px;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width : 375px) {
  h2 {
    font-size: 18px;
  }
}

#root {
  min-height: 100vh;
  overflow: hidden;
}

textarea {
  resize: none;
  font-family: Lato;
  font-weight: normal;
  font-size: 14px;
  outline: none;
  padding: 10px;

  &::placeholder {
    color: rgba(66, 69, 76, 0.5);
  }
}

a {
  color: #fff;
  text-decoration: none;
  font-weight: normal;
  font-size: 12px;
}

button {
  outline: none;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  line-height: normal;
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
}

@media only screen and (min-width : 768px) {
  input {
    height: 32px;
    background: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    outline: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);

    &::placeholder {
      color: rgba(66, 69, 76, 0.5);
    }
    // ADD NEW BS COLOR
    &:focus {
      box-shadow: 1px 0px 3px 1px #3f73f9;
    }
  }
}

@media only screen and (max-width : 768px) {
  input {
    height: 32px;
    background: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    outline: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);

    &::placeholder {
      color: rgba(66, 69, 76, 0.5);
    }
    // ADD NEW BS COLOR
    &:focus {
      box-shadow: none;
    }
  }
}

.MuiOutlinedInput-input.focus-visible {
  box-shadow: 0px 1.5px 1px 1px #3f73f9;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-color: #f0f1f3;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(180deg, #6b58e0 0%, #3f73f9 100%);
}
