@media only screen and (min-width : 768px) {
  .BudgetManagement {
    width: 115px;
    padding: 5px 10px;
    background: #f0f1f3;
    border-radius: 4px;
    margin: 0 10px 10px 0;

    .management {
      font-weight: 300;
      font-size: 12px;
      margin-bottom: 5px;
    }

    .budget {
      font-weight: 500;
      font-size: 14px;
    }
  }
}

@media only screen and (max-width : 375px) {
  .BudgetManagement {
    width: 115px;
    padding: 5px 5px;
    background: #f0f1f3;
    border-radius: 4px;
    margin: 0 10px 10px 0;

    .management {
      font-weight: 300;
      font-size: 12px;
      margin-bottom: 5px;
    }

    .budget {
      font-weight: 500;
      font-size: 14px;
    }
  }
}

@media only screen and (min-width : 375px) and (max-width: 767px) {
  .BudgetManagement {
    width: 145px;
    padding: 5px 5px;
    background: #f0f1f3;
    border-radius: 4px;
    margin: 0 10px 10px 0;

    .management {
      font-weight: 300;
      font-size: 14px;
      margin-bottom: 5px;
    }

    .budget {
      font-weight: 500;
      font-size: 14px;
    }
  }
}
