@media only screen and (min-width : 1000px) {
  .CategoryStatistics {
    .CardWhite {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 150px;

      .title {
        color: rgba(66, 69, 76, 0.6);
      }

      .amount {
        font-weight: 600;
        font-size: 24px;
        margin: 8px 0 4px 0;
        white-space: nowrap;
      }

      .difference {
        font-weight: 600;
        color: #3f73f9;
      }
    }
  }
}

@media only screen and (max-width : 768px) {
  .CategoryStatistics {
    .CardWhite {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 106px;
      padding: 20px 25px;
      margin: 20px 0 10px 5px;

      .title {
        color: rgba(66, 69, 76, 0.6);
        font-size: 12px;
      }

      .amount {
        font-weight: 600;
        font-size: 18px;
        margin: 8px 0 4px 0;
        white-space: nowrap;
      }

      .difference {
        font-weight: 600;
        color: #3f73f9;
      }
    }
  }
}