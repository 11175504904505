.AddCompany {
	.Card {
		margin-top: 40px;

		.content {
			padding: 0;

			.TabLinks {
				margin-bottom: 40px;
				border-radius: 24px 24px 0 0;
			}
		}
	}
}
