@media only screen and (min-width : 1000px) {
  $blue: #3F73F9;

  .dragged_item {
    position: absolute;
    opacity: 0.5
  }

  .banner_wrap {
    padding: 24px;
  }

  .CreativeButtonBox {
    display: flex;
    width: 210px;
    margin-top: -50px;
    margin-left: 1025px;
    .CreativeButton {
        background: linear-gradient(180deg, #6B58E0 0%, #3F73F9 100%);
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        width: 210px;
        height: 32px;
        color: #FFFFFF;
      }
  }

  .format_item {
    width: 100%;
    height: 31px;
    align-items: center;
    justify-content: flex-start;
    padding: 7px 12px;
    border-radius: 4px;
    background: #F0F1F3;
    font-size: 14px;
    cursor: pointer;
    //color: active ? white : rgba(66; 69; 76; 0.5);
    transition: all 0.5s ease-in-out;

    &:active, &:hover, &.active {
      background: #3F73F9;
      color: white
    }
  }

  input:focus {
    box-shadow: none;
  }

  .title_small {
    font-size: 12px;
  }

  .fileUpload {
    width: 228px;
    height: 228px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
  }

  .banner_load_wrap {
    display: flex;
    //height: 450px;
    border: 2px solid #F0F1F3;
    border-radius: 10px;
    padding: 22px 31px 36px;
    margin-bottom: 24px;
    box-sizing: border-box;
  }

  .cpm_wrap {
    display: flex;
    align-items: center;
    margin-bottom: 26px;
    max-width: 100%;
    gap: 20px;

    .cpm_input {
      width: 305px;
    }
  }

  .select_size_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 290px;
    border: 1px solid $blue;
    border-radius: 4px;
    background: #DEE7FF;
    gap: 10px;
    margin-bottom: 24px;

    p {
      color: $blue;
      cursor: pointer;
    }
  }

  .carousel {
    overflow: hidden;
    transition: transform 0.5s ease-in-out;
    display: flex;
    align-items: center;
    gap: 20px;
    height: 225px;
    width: fit-content;

    .sizes {
      background:black;
      opacity: 0.5;
      height: 40px;
      width: 100%;
      padding-top: 10px;
      display: flex;
      position: absolute;
      top: 0;
      justify-content: center;
    }

    .delete {
      position: absolute;
      right: 4px;
      top: 2px;
      color: white;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: red;
      }
    }

    .addBanner {
      position: absolute;
      right: 5px;
      bottom: 5px;
      padding: 5px;
      color: white;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: #76ff9d;
      }
    }

    .banner_img {
      width: 100%;
      height: 100%;
    }
  }

  .banner_numbers {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;

    .banner_number {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      background: #DEE7FF;
      width: 32px;
      min-width: 32px;
      height: 32px;
      border-radius: 4px;
      color: rgba(66, 69, 76, 0.2);
      cursor: pointer;

      &:disabled {
        background: #F0F1F3;
      }

      &.active {
        color: white;
        background: $blue;
      }
    }
  }

  .button_controls {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  .button_main {
    width: 200px;
  }

  .mb_2 {
    margin-bottom: 24px;
  }

  .mb_3 {
    margin-bottom: 36px;
  }

  .mb_4 {
    margin-bottom: 48px;
  }

  .small_title {
    font-size: 12px;
    font-weight: 600;
    line-height: 14.4px;
  }

  .c_blue {
    color: $blue;
  }

  .droparea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 228px;
    border: 1px dashed #3F73F9;
    border-radius: 4px;
    background: #DEE7FF;
    gap: 5px;
    padding: 20px;
    transition: background-color 0.5s ease-in-out;

    &.active {
      background: rgb(190, 234, 190);
    }

    div {
      text-align: center;
    }
  }

  .btn_add {

  }
}

@media screen and (max-width: 768px) {
  .title-text {
      margin-bottom: 60px !important;
      // margin-top: 40px !important;
      color: rgba(66, 69, 76, 0.60);
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 343px;

      h2 {
      color: var(--colors-42454-c, #42454C);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      }
  }
}

@media only screen and (max-width : 375px) {
  $blue: #3F73F9;

  .dragged_item {
    position: absolute;
    opacity: 0.5
  }

  .banner_wrap {
    // padding: 24px;
  }

  .CreativeButtonBox {
    display: flex;
    // justify-content: end;

    .CreativeButton {
        margin-top: -30px;
        background: linear-gradient(180deg, #6B58E0 0%, #3F73F9 100%);
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        // font-family: 'Lato';
        // font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        width: 288px;
        height: 32px;
        color: #FFFFFF;
      }
  }

  .format_item {
    width: 100%;
    height: 31px;
    align-items: center;
    justify-content: flex-start;
    padding: 7px 12px;
    border-radius: 4px;
    background: #F0F1F3;
    font-size: 12px;
    cursor: pointer;
    //color: active ? white : rgba(66; 69; 76; 0.5);
    transition: all 0.5s ease-in-out;

    &:active, &:hover, &.active {
      background: #3F73F9;
      color: white
    }
  }

  input:focus {
    box-shadow: none;
  }

  .title_small {
    font-size: 12px;
  }

  .fileUpload {
    width: 228px;
    height: 228px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
  }

  .banner_load_wrap {
    display: flex;
    flex-direction: column;
    //height: 450px;
    border: 2px solid #F0F1F3;
    // align-items: center;
    border-radius: 10px;
    padding: 10px;
    margin-top: 20px;
    // padding: 4px 10px 10px;
    margin-bottom: 24px;
    box-sizing: border-box;

    span {
      margin: 0 5px;
      color: #3f73f9;
      cursor: pointer;
    }
      
    .row {
      display: flex;
      flex-direction: row;
    }
  
    svg {
      &.norotate {
        transform: rotate(180deg);
      }
  
      &.rotated {
        transform: rotate(0deg);
      }
    }
  
    .popup {
      position: absolute;
      right: 0;
      // margin-top: 15px;
      background: #F0F1F3;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.09);
      border-radius: 10px;
      overflow: hidden;
      padding: 10px 0;
      width: 160px;
      z-index: 1;
  
      ul {
        overflow: hidden;
        li {
          padding: 12px 20px;
          cursor: pointer;
  
          &.active,
          &:hover {
            background: rgba(163, 148, 142, 0.05);
          }
  
          &.active {
            font-weight: bold;
            // color: blue;
          }
        }
      }
    }

    .carousel {
      overflow: hidden;
      transition: transform 0.5s ease-in-out;
      display: flex;
      flex-direction: row;
      margin-top: 60px;
      // margin-right: 40px;
      align-items: flex-start;
      // flex-wrap: wrap;
      // align-items: center;
      gap: 1px;
      // height: 225px;
      // width: fit-content;
  
      .sizes {
        background:black;
        opacity: 0.5;
        height: 35px;
        width: 90%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding-top: 10px;
        display: flex;
        position: absolute;
        top: 0;
        justify-content: center;
      }
  
      .delete {
        position: absolute;
        right: 10px;
        color: white;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
  
        &:hover {
          color: red;
        }
      }
    }
  }

  .cpm_wrap {
    display: flex;
    align-items: center;
    margin-bottom: 26px;
    max-width: 100%;
    gap: 20px;

    .cpm_input {
      width: 305px;
    }
  }

  .select_size_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 290px;
    border: 1px solid $blue;
    border-radius: 4px;
    background: #DEE7FF;
    gap: 10px;
    margin-bottom: 24px;

    p {
      color: $blue;
      cursor: pointer;
    }
  }

  .banner_img {
    width: 90%;
    height: 90%;
    border-radius: 10px;
  }
  

  .banner_numbers {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;

    .banner_number {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      background: #DEE7FF;
      width: 32px;
      min-width: 32px;
      height: 32px;
      border-radius: 4px;
      color: rgba(66, 69, 76, 0.2);
      cursor: pointer;

      &:disabled {
        background: #F0F1F3;
      }

      &.active {
        color: white;
        background: $blue;
      }
    }
  }

  .button_controls {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  .button_main {
    display: none;
    // width: 200px;
  }

  .mb_2 {
    margin-bottom: 24px;
  }

  .mb_3 {
    margin-bottom: 36px;
  }

  .mb_4 {
    margin-bottom: 48px;
  }

  .small_title {
    font-size: 12px;
    font-weight: 600;
    line-height: 14.4px;
  }

  .c_blue {
    color: $blue;
  }

  .droparea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    border: 1px dashed #3F73F9;
    border-radius: 4px;
    background: #DEE7FF;
    gap: 5px;
    padding: 20px;
    transition: background-color 0.5s ease-in-out;

    &.active {
      background: rgb(190, 234, 190);
    }

    div {
      text-align: center;
    }
  }

  .btn_add {
    display: none;
  }
}

@media only screen and (min-width : 375px) and (max-width: 767px) {
  $blue: #3F73F9;

  .dragged_item {
    position: absolute;
    opacity: 0.5
  }

  .banner_wrap {
    // padding: 24px;
  }

  .CreativeButtonBox {
    display: flex;
    // justify-content: end;

    .CreativeButton {
        margin-top: -30px;
        background: linear-gradient(180deg, #6B58E0 0%, #3F73F9 100%);
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        // font-family: 'Lato';
        // font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        width: 343px;
        height: 32px;
        color: #FFFFFF;
      }
  }

  .format_item {
    width: 100%;
    height: 31px;
    align-items: center;
    justify-content: flex-start;
    padding: 7px 12px;
    border-radius: 4px;
    background: #F0F1F3;
    font-size: 12px;
    cursor: pointer;
    //color: active ? white : rgba(66; 69; 76; 0.5);
    transition: all 0.5s ease-in-out;

    &:active, &:hover, &.active {
      background: #3F73F9;
      color: white
    }
  }

  input:focus {
    box-shadow: none;
  }

  .title_small {
    font-size: 12px;
  }

  .fileUpload {
    width: 100%;
    height: 228px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
  }

  .banner_load_wrap {
    display: flex;
    flex-direction: column;
    //height: 450px;
    border: 2px solid #F0F1F3;
    // align-items: center;
    border-radius: 10px;
    padding: 10px;
    margin-top: 20px;
    // padding: 4px 10px 10px;
    margin-bottom: 24px;
    box-sizing: border-box;

    span {
      margin: 0 5px;
      color: #3f73f9;
      cursor: pointer;
    }
      
    .row {
      display: flex;
      flex-direction: row;
    }
  
    svg {
      &.norotate {
        transform: rotate(180deg);
      }
  
      &.rotated {
        transform: rotate(0deg);
      }
    }
  
    .popup {
      position: absolute;
      right: 0;
      // margin-top: 15px;
      background: #F0F1F3;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.09);
      border-radius: 10px;
      overflow: hidden;
      padding: 10px 0;
      width: 160px;
      z-index: 1;
  
      ul {
        overflow: hidden;
        li {
          padding: 12px 20px;
          cursor: pointer;
  
          &.active,
          &:hover {
            background: rgba(163, 148, 142, 0.05);
          }
  
          &.active {
            font-weight: bold;
            // color: blue;
          }
        }
      }
    }

    .carousel {
      overflow: hidden;
      transition: transform 0.5s ease-in-out;
      display: flex;
      flex-direction: row;
      margin-top: 60px;
      // margin-right: 40px;
      align-items: flex-start;
      // flex-wrap: wrap;
      // align-items: center;
      gap: 1px;
      // height: 225px;
      // width: fit-content;
  
      .sizes {
        background:black;
        opacity: 0.5;
        height: 35px;
        width: 90%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding-top: 10px;
        display: flex;
        position: absolute;
        top: 0;
        justify-content: center;
      }
  
      .delete {
        position: absolute;
        right: 10px;
        color: white;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
  
        &:hover {
          color: red;
        }
      }
    }
  }

  .cpm_wrap {
    display: flex;
    align-items: center;
    margin-bottom: 26px;
    max-width: 100%;
    gap: 20px;

    .cpm_input {
      width: 305px;
    }
  }

  .select_size_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 290px;
    border: 1px solid $blue;
    border-radius: 4px;
    background: #DEE7FF;
    gap: 10px;
    margin-bottom: 24px;

    p {
      color: $blue;
      cursor: pointer;
    }
  }

  .banner_img {
    width: 90%;
    height: 90%;
    border-radius: 10px;
  }
  

  .banner_numbers {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;

    .banner_number {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      background: #DEE7FF;
      width: 32px;
      min-width: 32px;
      height: 32px;
      border-radius: 4px;
      color: rgba(66, 69, 76, 0.2);
      cursor: pointer;

      &:disabled {
        background: #F0F1F3;
      }

      &.active {
        color: white;
        background: $blue;
      }
    }
  }

  .button_controls {
    display: flex;
    justify-content: flex-end;
    gap: 75px;
  }

  .button_main {
    display: none;
    // width: 200px;
  }

  .mb_2 {
    margin-bottom: 24px;
  }

  .mb_3 {
    margin-bottom: 36px;
  }

  .mb_4 {
    margin-bottom: 48px;
  }

  .small_title {
    font-size: 12px;
    font-weight: 600;
    line-height: 14.4px;
  }

  .c_blue {
    color: $blue;
  }

  .droparea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    border: 1px dashed #3F73F9;
    border-radius: 4px;
    background: #DEE7FF;
    gap: 5px;
    padding: 20px;
    transition: background-color 0.5s ease-in-out;

    &.active {
      background: rgb(190, 234, 190);
    }

    div {
      text-align: center;
    }
  }

  .btn_add {
    display: none;
  }
}

@media only screen and (min-width : 768px) and (max-width: 1000px) {
  $blue: #3F73F9;

  .dragged_item {
    position: absolute;
    opacity: 0.5
  }

  .banner_wrap {
    // padding: 24px;
  }

  .CreativeButtonBox {
    display: flex;
    // justify-content: end;

    .CreativeButton {
        margin-top: -30px;
        background: linear-gradient(180deg, #6B58E0 0%, #3F73F9 100%);
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        // font-family: 'Lato';
        // font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        width: 343px;
        height: 32px;
        color: #FFFFFF;
      }
  }

  .format_item {
    width: 100%;
    height: 31px;
    align-items: center;
    justify-content: flex-start;
    padding: 7px 12px;
    border-radius: 4px;
    background: #F0F1F3;
    font-size: 12px;
    cursor: pointer;
    //color: active ? white : rgba(66; 69; 76; 0.5);
    transition: all 0.5s ease-in-out;

    &:active, &:hover, &.active {
      background: #3F73F9;
      color: white
    }
  }

  input:focus {
    box-shadow: none;
  }

  .title_small {
    font-size: 12px;
  }

  .fileUpload {
    width: 100%;
    height: 228px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
  }

  .banner_load_wrap {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 650px;
    //height: 450px;
    border: 2px solid #F0F1F3;
    // align-items: center;
    border-radius: 10px;
    padding: 10px;
    // margin-top: 20px;
    // padding: 4px 10px 10px;
    margin-top: 10px;
    margin-right: 20px;
    margin-bottom: 24px;
    box-sizing: border-box;

    span {
      margin: 0 5px;
      color: #3f73f9;
      cursor: pointer;
    }
      
    .row {
      display: flex;
      flex-direction: row;
      width: 205px;
      flex-wrap: wrap;
    }
  
    svg {
      &.norotate {
        transform: rotate(180deg);
      }
  
      &.rotated {
        transform: rotate(0deg);
      }
    }
  
    .popup {
      position: absolute;
      left: 200px;
      right: 0;
      // margin-top: 15px;
      background: #F0F1F3;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.09);
      border-radius: 10px;
      overflow: hidden;
      padding: 10px 0;
      width: 160px;
      z-index: 1;
  
      ul {
        overflow: hidden;
        li {
          padding: 12px 20px;
          cursor: pointer;
  
          &.active,
          &:hover {
            background: rgba(163, 148, 142, 0.05);
          }
  
          &.active {
            font-weight: bold;
            // color: blue;
          }
        }
      }
    }

    .carousel {
      overflow: hidden;
      transition: transform 0.5s ease-in-out;
      display: flex;
      flex-direction: row;
      margin-top: 30px;
      align-items: flex-start;
      // flex-wrap: wrap;
      // align-items: center;
      gap: 1px;
      // height: 225px;
      // width: fit-content;
  
      .sizes {
        background:black;
        opacity: 0.5;
        height: 35px;
        width: 90%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding-top: 10px;
        display: flex;
        position: absolute;
        top: 0;
        justify-content: center;
      }
  
      .delete {
        position: absolute;
        right: 10px;
        color: white;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
  
        &:hover {
          color: red;
        }
      }
    }
  }

  .cpm_wrap {
    display: flex;
    align-items: center;
    margin-bottom: 26px;
    max-width: 100%;
    gap: 20px;

    .cpm_input {
      width: 305px;
    }
  }

  .select_size_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 290px;
    border: 1px solid $blue;
    border-radius: 4px;
    background: #DEE7FF;
    gap: 10px;
    margin-bottom: 24px;

    p {
      color: $blue;
      cursor: pointer;
    }
  }

  .banner_img {
    width: 90%;
    height: 90%;
    border-radius: 10px;
  }
  

  .banner_numbers {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;

    .banner_number {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      background: #DEE7FF;
      width: 32px;
      min-width: 32px;
      height: 32px;
      border-radius: 4px;
      color: rgba(66, 69, 76, 0.2);
      cursor: pointer;

      &:disabled {
        background: #F0F1F3;
      }

      &.active {
        color: white;
        background: $blue;
      }
    }
  }

  .button_controls {
    display: flex;
    justify-content: space-between;
    gap: 75px;
  }

  .button_main {
    display: none;
    // width: 200px;
  }

  .mb_2 {
    width: 200px;
    margin-bottom: 24px;
  }

  .mb_3 {
    margin-bottom: 36px;
  }

  .mb_4 {
    margin-bottom: 48px;
  }

  .small_title {
    font-size: 12px;
    font-weight: 600;
    line-height: 14.4px;
  }

  .c_blue {
    color: $blue;
  }

  .droparea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    width: 200px;
    border: 1px dashed #3F73F9;
    border-radius: 4px;
    background: #DEE7FF;
    gap: 5px;
    padding: 20px;
    transition: background-color 0.5s ease-in-out;

    &.active {
      background: rgb(190, 234, 190);
    }

    div {
      text-align: center;
    }
  }

  .btn_add {
    display: none;
  }
}