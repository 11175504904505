.Switch {
  width: 40px;
  height: 20px;
  background: #f0f1f3;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 34px;
  cursor: pointer;
  padding: 2px;

  .slider {
    width: 16px;
    height: 16px;
    background-color: #ccc;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 50%;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  &.isSelectedSlider {
    background: linear-gradient(180deg, #6b58e0 0%, #3f73f9 100%);

    .slider {
      background: #ffffff;
      transform: translateX(21px);
    }

    &.BLOCKED {
      background: #f93766;
    }
  }
}
