.email_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  .confirmButton {
    margin-top: 40px;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    padding-top: 5px;
    color: #ffffff;
    width: 100%;
    height: 32px;
    background: #3f73f9;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
      0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
  }
}
