@media only screen and (min-width : 1000px) {
  .CompanyBubble {
    overflow: hidden;
    position: relative;

    .Card {
      overflow: hidden;
      &.NOT_DELIVERING {
        border-color: #f9143a;
      }

      &.PENDING {
        border-color: #f37318;
      }

      &.NO_ACTIVE {
        border-color: #42454c;
      }

      &.DELIVERING {
        border-color: #3f73f9;
      }

      .content {
        display: flex;
        padding: 40px 40px 40px 60px;
        .metrics {
          display: flex;
          flex-direction: row;
          gap: 15px;
          width: 1000px;
        }
      }
    }

    .settingsButton {
      width: 40px;
      cursor: pointer;
      outline: none;
      border: none;
      background: linear-gradient(180deg, #6b58e0 0%, #3f73f9 100%);
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
      margin-right: 20px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;

      &.NOT_DELIVERING {
        background: #f9143a; // #42454c;
      }

      &.NO_ACTIVE {
        background: #42454c;
      }

      &.PENDING {
        background: #f37318;
      }

      &.DELIVERING {
        background: #3f73f9;
      }

      &_icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .column {

      flex-grow: 1;

      .client {
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;

        .span {
          margin: 0 10px;
        }
      }

      .category {
        min-width: 480px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .Catalog {
          margin: 0 10px 10px 0;
        }
      }

      .title {
        letter-spacing: 0.04em;
      }

      .items {
        display: flex;
        width: 240px;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 10px;

        .BudgetManagement:last-child {
          margin-right: 0;
        }

        .statistics {
          background: none;
          width: 90px;
        }
      }

      .items2 {
        display: flex;
        margin-top: 10px;
        
        .statistics {
          background: none;
          max-width: 80px;
        }
      }

      .button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 10px;

        .Button {
          width: 210px;
        }
      }
    }

    .Line {
      margin: 0 40px;
    }
  }
}

@media only screen and (max-width : 375px) {
  .CompanyBubble {
    overflow: hidden;
    position: relative;

    .Card {
      border-radius: 10px;
      // overflow: hidden;
      &.NOT_DELIVERING {
        border-color: #f9143a;
      }

      &.PENDING {
        border-color: #f37318;
      }

      &.NO_ACTIVE {
        border-color: #42454c;
      }

      &.DELIVERING {
        border-color: #3f73f9;
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 10px 30px 10px 20px;
      }
    }
    .settingsButton {
      max-width: 5px;
      // position:absolute;
      // top:0;
      // right:0;
      &.NOT_DELIVERING {
          background: #f9143a; // #42454c;
        }
  
        &.NO_ACTIVE {
          background: #42454c;
        }
  
        &.PENDING {
          background: #f37318;
        }
  
        &.DELIVERING {
          background: #3f73f9;
        }
    // display: none;
        &_icon {
          position:absolute;
          top:0;
          left:0;
          margin-left: 255px;
          margin-top: 10px;
          filter: brightness(0) saturate(90%) contrast(50%);
          opacity: 0.5;
      }

      Card {
        margin: 300px;
      }
    }

    .padding {
      margin-top: 10px;
    }

    .column {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .client {
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;

        .span {
          margin: 0 10px;
        }
      }

      .category {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .Catalog {
          margin: 0 10px 10px 0;
        }
      }

      .title {
        letter-spacing: 0.04em;
      }

      .items {
        display: flex;
        width: 240px;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 10px;

        .BudgetManagement:last-child {
          margin-right: 0;
        }

        .statistics {
          background: none;
          width: 100px;
        }
      }

      .items2 {
        display: flex;
        flex-direction: row;
        width: 260px;
        margin-top: 10px;
        
        .statistics {
          background: none;
          width: 100%;
        }
      }

      .button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 10px;

        .Button {
          width: 210px;
        }
      }
    }

    .Line {
      display: none;
    }
  }
}

@media only screen and (min-width : 375px) and (max-width: 767px) {
  .CompanyBubble {
    overflow: hidden;
    position: relative;

    .Card {
      border-radius: 10px;
      // overflow: hidden;
      &.NOT_DELIVERING {
        border-color: #f9143a;
      }

      &.PENDING {
        border-color: #f37318;
      }

      &.NO_ACTIVE {
        border-color: #42454c;
      }

      &.DELIVERING {
        border-color: #3f73f9;
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 10px 30px 10px 20px;
      }
    }
    .settingsButton {
      max-width: 5px;
      // position:absolute;
      // top:0;
      // right:0;
      &.NOT_DELIVERING {
          background: #f9143a; // #42454c;
        }
  
        &.NO_ACTIVE {
          background: #42454c;
        }
  
        &.PENDING {
          background: #f37318;
        }
  
        &.DELIVERING {
          background: #3f73f9;
        }
    // display: none;
        &_icon {
          position:absolute;
          top:0;
          left:0;
          margin-left: 305px;
          margin-top: 10px;
          filter: brightness(0) saturate(90%) contrast(50%);
          opacity: 0.5;
      }

      Card {
        margin: 300px;
      }
    }

    .padding {
      margin-top: 10px;
    }

    .column {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .client {
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;

        .span {
          margin: 0 10px;
        }
      }

      .category {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .Catalog {
          margin: 0 10px 10px 0;
        }
      }

      .title {
        letter-spacing: 0.04em;
      }

      .items {
        display: flex;
        width: 343px;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 10px;

        .BudgetManagement:last-child {
          margin-right: 0;
        }

        .statistics {
          background: none;
          width: 100px;
        }
      }

      .items2 {
        display: flex;
        flex-direction: row;
        width: 343px;
        margin-top: 10px;
        
        .statistics {
          background: none;
          width: 100%;
        }
      }

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        // justify-content: flex-end;
        margin-top: 10px;

        .Button {
          width: 210px;
        }
      }
    }

    .Line {
      display: none;
    }
  }
}

@media only screen and (min-width : 768px) and (max-width: 1000px) {
.CompanyBubble {
  overflow: hidden;
  position: relative;
  padding: 0px;

    .Card {
      padding: 0px;
      border-radius: 10px;
      // overflow: hidden;
      &.NOT_DELIVERING {
        border-color: #f9143a;
      }

      &.PENDING {
        border-color: #f37318;
      }

      &.NO_ACTIVE {
        border-color: #42454c;
      }

      &.DELIVERING {
        border-color: #3f73f9;
      }

      .content {
        display: flex;
        padding: 20px;

        .metrics {
          display: flex;
          flex-direction: column;
          gap: 15px;
          width: 300px;
        // padding: 10px 30px 10px 20px;
        }
      }
    }
    .settingsButton {
      max-width: 5px;
      // position:absolute;
      // top:0;
      // right:0;
      &.NOT_DELIVERING {
          background: #f9143a; // #42454c;
        }
  
        &.NO_ACTIVE {
          background: #42454c;
        }
  
        &.PENDING {
          background: #f37318;
        }
  
        &.DELIVERING {
          background: #3f73f9;
        }
    // display: none;
        &_icon {
          position:absolute;
          top:0;
          left:0;
          margin-left: 660px;
          margin-top: 10px;
          filter: brightness(0) saturate(90%) contrast(50%);
          opacity: 0.5;
      }

      Card {
        margin: 300px;
      }
    }

  .column {

    flex-grow: 1;

    .client {
      display: flex;
      align-items: flex-end;
      margin-bottom: 10px;

      .span {
        margin: 0 10px;
      }
    }

    .category {
      min-width: 200px;
      width: 350px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .Catalog {
        margin: 0 10px 10px 0;
      }
    }

    .title {
      letter-spacing: 0.04em;
    }

    .items {
      display: flex;
      width: 240px;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;

      .BudgetManagement:last-child {
        margin-right: 0;
      }

      .statistics {
        background: none;
        width: 90px;
      }
    }

    .items2 {
      display: flex;
      margin-top: 10px;
      
      .statistics {
        background: none;
        max-width: 80px;
      }
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 10px;

      .Button {
        width: 210px;
      }
    }
  }

  .Line {
    display: none;
  }
}
}
