@media only screen and (min-width : 992px) {
  .error-container {
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    background: rgba(255, 0, 0, 0.6);
    color: white;
    gap: 10px;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }
}

@media only screen and (max-width : 374px) {
  .error-container {
    top: 0;
    width: 288px;
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    background: rgba(255, 0, 0, 0.6);
    color: white;
    gap: 10px;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }
}

@media only screen and (min-width : 375px) and (max-width: 768px) {
  .error-container {
    top: 0;
    width: 343px;
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    background: rgba(255, 0, 0, 0.6);
    color: white;
    gap: 10px;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }
}

@media only screen and (min-width : 768px) and (max-width: 1000px) {
  .error-container {
    top: 0;
    width: 460px;
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    background: rgba(255, 0, 0, 0.6);
    color: white;
    gap: 10px;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }
}