@media screen and (max-width : 375px) {
    .css-hz1bth-MuiDialog-container {
        .css-1b25eem-MuiFormControl-root-MuiTextField-root {
            width: auto;

            .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
                font-size: 11px;
            }
        }

        .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
            font-size: 11px;
            top: 5px;
        }
    }

    .css-1kwtu5z-MuiTable-root {
        display: flex !important;

        .css-1enotpu-MuiTableRow-root {
            display: flex;
            flex-direction: column;

            td {
                max-width: 500px;
            }
        }
    }

    .css-ekeie0 {
        .css-kbyj7x {
            width: auto;

            .css-p0rm37 {
                font-size: 11px;
            }
        }

        .css-p0rm37 {
            top: 5px;
            font-size: 11px;
        }
    }

    .css-1hcle96 {
        display: flex !important;

        .css-1kxl50g {
            display: flex;
            flex-direction: column;

            td {
                max-width: 500px;
            }
        }
    }


    .createSegment {
        width: 288px;

        .css-y62u9e-MuiFormControl-root-MuiTextField-root {
            width: 287px !important;
            height: 27px;
        }

        .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
            top: -11px !important;
            color: rgba(66, 69, 76, 0.50) !important;

            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
        }

        .css-p0rm37 {
            top: -11px !important;
            color: rgba(66, 69, 76, 0.50) !important;

            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
        }

        .css-p0rm37.Mui-focused {
            color: rgba(66, 69, 76, 0.50) !important;
            top: -7px !important;
        }

        .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
            color: rgba(66, 69, 76, 0.50) !important;
            top: -7px !important;
        }

        .Mui-error {
            color: red !important;
        }

        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
            height: 27px;
            width: 287px;
            border-radius: 4px;
            background: #F0F1F3;
            box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

            input {
                font-size: 14px;
                padding: 0;
                padding-left: 10px;
                box-shadow: none;
            }

            fieldset {
                border: none;
            }
        }

        .css-1v4ccyo {
            height: 27px;
            width: 287px;
            border-radius: 4px;
            background: #F0F1F3;
            box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

            input {
                font-size: 14px;
                padding: 0;
                padding-left: 10px;
                box-shadow: none;
            }

            fieldset {
                border: none;
            }
        }

        .socialItem {
            padding-top: 0 !important;
        }

        .gridItem {
            padding-top: 18px !important;
            margin-bottom: 0 !important;
        }

        .dropzone1 {
            color: #3f73f9;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 8px !important;
            padding: 20px;
            cursor: pointer;
            height: 80px;
            width: 100%;
            background: #eff4ff;
            border: 1px dashed #3f73f9;
            outline: none;
            border-radius: 5px;
        }

        .dropzoneText {
            padding-top: 5px;
            height: 27px;
            color: rgba(66, 69, 76, 0.50);

            font-size: 14px !important;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-left: 10px;
            text-align: start !important;
            border-radius: 4px;
            background: #F0F1F3;
            box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);
        }

        .media-plan__drag-text {
            font-size: 10px;
            line-height: 9px;
            color: #3f73f9;
        }

        .title {
            margin: 0 !important;
            color: var(--colors-42454-c, #42454C) !important;

            font-size: 13.434px !important;
            font-style: normal !important;
            font-weight: 700 !important;
            line-height: normal !important;
            text-transform: capitalize !important;
        }
    }
}

@media screen and (min-width:375px) and (max-width : 1000px) {
    .css-hz1bth-MuiDialog-container {
        .css-1b25eem-MuiFormControl-root-MuiTextField-root {
            width: auto;

            .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
                font-size: 11px;
            }
        }

        .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
            font-size: 11px;
            top: 5px;
        }
    }

    .css-1kwtu5z-MuiTable-root {
        display: flex !important;

        .css-1enotpu-MuiTableRow-root {
            display: flex;
            flex-direction: column;

            td {
                max-width: 500px;
            }
        }
    }


    .createSegment {
        width: 343px;

        .css-y62u9e-MuiFormControl-root-MuiTextField-root {
            width: 342px !important;
            height: 32px;
        }

        .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
            top: -11px !important;
            color: rgba(66, 69, 76, 0.50) !important;

            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
        }

        .css-p0rm37 {
            top: -11px !important;
            color: rgba(66, 69, 76, 0.50) !important;

            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
        }

        .css-p0rm37.Mui-focused {
            color: rgba(66, 69, 76, 0.50) !important;
            top: -7px !important;
        }

        .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
            color: rgba(66, 69, 76, 0.50) !important;
            top: -7px !important;
        }

        .Mui-error {
            color: red !important;
        }

        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
            height: 32px;
            width: 342px;
            border-radius: 4px;
            background: #F0F1F3;
            box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

            input {
                font-size: 14px;
                padding: 0;
                padding-left: 10px;
                box-shadow: none;
            }

            fieldset {
                border: none;
            }
        }

        .css-1v4ccyo {
            height: 32px;
            width: 342px;
            border-radius: 4px;
            background: #F0F1F3;
            box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

            input {
                font-size: 14px;
                padding: 0;
                padding-left: 10px;
                box-shadow: none;
            }

            fieldset {
                border: none;
            }
        }

        .socialItem {
            padding-top: 0 !important;
        }

        .gridItem {
            padding-top: 18px !important;
            margin-bottom: 0 !important;
        }

        .dropzone1 {
            color: #3f73f9;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 8px !important;
            padding: 20px;
            cursor: pointer;
            height: 80px;
            width: 100%;
            background: #eff4ff;
            border: 1px dashed #3f73f9;
            outline: none;
            border-radius: 5px;
        }

        .dropzoneText {
            padding-top: 5px;
            height: 32px;
            color: rgba(66, 69, 76, 0.50);

            font-size: 14px !important;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-left: 10px;
            text-align: start !important;
            border-radius: 4px;
            background: #F0F1F3;
            box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);
        }

        .media-plan__drag-text {
            font-size: 10px;
            line-height: 9px;
            color: #3f73f9;
        }

        .title {
            margin: 0 !important;
            color: var(--colors-42454-c, #42454C) !important;

            font-size: 13.434px !important;
            font-style: normal !important;
            font-weight: 700 !important;
            line-height: normal !important;
            text-transform: capitalize !important;
        }
    }
}

@media screen and (min-width:768px) and (max-width : 1000px) {
    .createSegment {
        width: 656px;

        .css-tmctnw-MuiGrid-root {
            margin-left: -50px;
        }

        .css-tmctnw-MuiGrid-root>.MuiGrid-item {
            padding-left: 45px;
        }

        .css-x7rk98>.MuiGrid-item {
            padding-left: 45px;
        }

        .css-w15plh-MuiGrid-root {
            max-width: 100%;
        }

        .css-15a9yhk {
            max-width: 100%;
        }

        .dropzone1 {
            width: 635px;
            text-align: center;
        }
    }
}