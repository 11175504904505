@media only screen and (min-width : 1000px) {
  .Company {
    .filter {
      display: flex;
      align-items: baseline;
      margin-bottom: 20px;

      .Button {
        width: 210px;
        margin-right: 45px;
      }

      .rate {
        svg {
          margin-right: 6px;
        }
      }

      .SortPopup {
        margin-left: 5px;
      }

      a {
        margin-left: auto;
      }
    }

    .Card {
      margin-top: 20px;
    }
  }

  .Modal {
    .wrapper {
      .content {
        .search__field {
          position: relative;

          input {
            width: 100%;
            padding: 8px 40px 8px 10px;
            background: #fff;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
              0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            margin: 0;
          }

          .search-icon {
            position: absolute;
            top: 7px;
            right: 20px;
          }
        }

        .about {
          margin: 20px 0;
        }

        .Checkbox {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .btn-actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 20px;

          .Button {
            max-width: 210px;
          }

          .secondary {
            width: 210px;
          }

          .primary {
            margin-left: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width : 375px) {
  .Company {
    margin-top: 10px;
    width: 288px;
    overflow-x: hidden;
    .filter {
      display: flex;
      flex-direction: column;
      // align-items: baseline;
      margin-bottom: 20px;
      width: 288px;

      .Button {
        width: 288px;
        margin-top: -10px;
        margin-bottom: 10px;
      }

      .rate {
        svg {
          margin-right: 6px;
        }
      }

      .SortPopup {
        // margin-left: 5px;
      }

      a {
        margin-left: auto;
      }
    }

    .Card {
      margin-top: 20px;
    }
  }

  .Modal {
    .wrapper {
      .content {
        .search__field {
          position: relative;

          input {
            width: 100%;
            padding: 8px 40px 8px 10px;
            background: #fff;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
              0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            margin: 0;
          }

          .search-icon {
            position: absolute;
            top: 7px;
            right: 20px;
          }
        }

        .about {
          margin: 20px 0;
        }

        .Checkbox {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .btn-actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 20px;

          .Button {
            max-width: 210px;
          }

          .secondary {
            width: 210px;
          }

          .primary {
            margin-left: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width : 375px) and (max-width: 767px) {
  .Company {
    margin-top: 10px;
    width: 343px;
    overflow-x: hidden;
    .filter {
      display: flex;
      flex-direction: column;
      // align-items: baseline;
      margin-bottom: 20px;
      width: 343px;

      .Button {
        width: 343px;
        margin-top: -10px;
        margin-bottom: 10px;
      }

      .rate {
        svg {
          margin-right: 6px;
        }
      }

      .SortPopup {
        // margin-left: 5px;
      }

      a {
        margin-left: auto;
      }
    }

    .Card {
      margin-top: 20px;
    }
  }

  .Modal {
    .wrapper {
      .content {
        .search__field {
          position: relative;

          input {
            width: 100%;
            padding: 8px 40px 8px 10px;
            background: #fff;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
              0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            margin: 0;
          }

          .search-icon {
            position: absolute;
            top: 7px;
            right: 20px;
          }
        }

        .about {
          margin: 20px 0;
        }

        .Checkbox {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .btn-actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 20px;

          .Button {
            max-width: 210px;
          }

          .secondary {
            width: 210px;
          }

          .primary {
            margin-left: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width : 768px) and (max-width: 1000px){
  .Company {
    margin-top: 10px;
    width: 704px;
    overflow-x: hidden;
    .filter {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      margin-bottom: 20px;
      // width: 704px;

      .Button {
        width: 210px;
        margin-top: -10px;
        margin-bottom: 10px;
      }

      .rate {
        svg {
          margin-right: 6px;
        }
      }

      .SortPopup {
        // margin-left: 5px;
      }

      a {
        margin-left: auto;
      }
    }

    .Card {
      margin-top: 10px;
    }
  }

  .Modal {
    .wrapper {
      .content {
        .search__field {
          position: relative;

          input {
            width: 100%;
            padding: 8px 40px 8px 10px;
            background: #fff;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
              0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            margin: 0;
          }

          .search-icon {
            position: absolute;
            top: 7px;
            right: 20px;
          }
        }

        .about {
          margin: 20px 0;
        }

        .Checkbox {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .btn-actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 20px;

          .Button {
            max-width: 210px;
          }

          .secondary {
            width: 210px;
          }

          .primary {
            margin-left: 20px;
          }
        }
      }
    }
  }
}