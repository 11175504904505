.SignIn {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  flex-direction: row;

  .AdminLogo {
    display: flex;
    flex-direction: column;
    gap: 10px;
    line-height: 21px;
    font-size: 21px;
    background: #f0f1f3;
    height: 100vh;
    padding-top: 40px;
    padding-left: 40px;
    position: absolute;
    z-index: 0;
  }

  .wrapp {
    background: #f0f1f3;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .form {
      width: 460px;
      z-index: 1;

      &-title {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .sub-title {
        opacity: 0.6;
        font-weight: normal;
        margin-bottom: 20px;
      }

      .email-field,
      .password-field {
        width: 100%;
        margin-bottom: 5px;
      }

      .save-password {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .remember {
          display: flex;
          align-items: center;
          cursor: pointer;

          .body1 {
            margin-left: 10px;
          }
        }

        .forgot {
          color: #3f73f9;
        }
      }

      .btn-actions {
        margin-top: 20px;

        .Button {
          width: 100%;

          &.primary:disabled {
            background: #9ab5fb;
            color: #fff;
            cursor: default;
          }

          &.secondary:disabled {
            border: 1px solid #9ab5fb;
            color: #9ab5fb;
            cursor: default;
          }
        }

        .primary {
          margin-bottom: 10px;
        }
      }
    }
  }
}