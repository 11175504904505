@media only screen and (min-width: 1000px) {
  .Menu {
    display: flex;
    justify-content: space-between;
    background-color: #f1f1f1;
    border-top: 1px solid rgba(66, 69, 76, 0.1);
    border-bottom: 1px solid rgba(66, 69, 76, 0.1);
    width: 100%;

    .menu-container {
      display: flex;
      align-items: center;
      height: 60px;
      padding: 0 30px;
      margin: 0 auto;
      width: 1440px;

      a {
        color: #42454c;
        font-weight: 500;
        font-size: 16px;
        margin-right: 30px;
        position: relative;

        &.active::before {
          content: "";
          border-bottom: 3px solid #3f73f9;
          position: absolute;
          bottom: -20px;
          left: 0;
          width: 100%;
        }
      }

      a:last-child {
        margin-right: 0;
      }
    }
    .ya-account {
      height: 30px;
      margin-right: 72px;
      margin-top: 15px;
      display: flex;
      align-items: center;
      gap: 15px;
      .ya-account-text {
        color: #000;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.64px;
      }
      .ya-account-text:first-letter {
        color: #e00;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.64px;
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .Menu {
    background-color: #f0f1f3;
    // background-color: #f1f1f1;
    border-top: 1px solid rgba(66, 69, 76, 0.1);
    border-bottom: 1px solid rgba(66, 69, 76, 0.1);
    // max-width : 375px;
    width: 100vw;
    // height: 100vh;

    .menu-container {
      overflow-x: scroll;
      display: flex;
      // flex-direction: row;
      align-items: center;
      height: 45px;
      width: 320px;
      padding: 0 15px;
      margin: 0 auto;
      white-space: nowrap;

      a {
        color: #42454c;
        font-weight: 500;
        font-size: 12px;
        margin-right: 30px;
        position: relative;

        &.active::before {
          content: "";
          // display: none;
          border-bottom: 3px solid #3f73f9;
          position: absolute;
          bottom: -5px;
          left: 0;
          width: 100%;
        }
      }

      a:last-child {
        margin-right: 15px;
      }
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #f1f1f1;
    }
  }
}

@media only screen and (min-width: 375px) and (max-width: 768px) {
  .Menu {
    background-color: #f0f1f3;
    // background-color: #f1f1f1;
    border-top: 1px solid rgba(66, 69, 76, 0.1);
    border-bottom: 1px solid rgba(66, 69, 76, 0.1);
    // max-width : 375px;
    width: 100vw;
    // height: 100vh;

    .menu-container {
      overflow-x: scroll;
      display: flex;
      // flex-direction: row;
      align-items: center;
      height: 45px;
      width: 375px;
      padding: 0 15px;
      margin: 0 auto;
      white-space: nowrap;

      a {
        color: #42454c;
        font-weight: 500;
        font-size: 12px;
        margin-right: 30px;
        position: relative;

        &.active::before {
          content: "";
          // display: none;
          border-bottom: 3px solid #3f73f9;
          position: absolute;
          bottom: -5px;
          left: 0;
          width: 100%;
        }
      }

      a:last-child {
        margin-right: 15px;
      }
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #f1f1f1;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .Menu {
    background-color: #f0f1f3;
    // background-color: #f1f1f1;
    border-top: 1px solid rgba(66, 69, 76, 0.1);
    border-bottom: 1px solid rgba(66, 69, 76, 0.1);
    // max-width : 375px;
    width: 100vw;
    // height: 100vh;

    .menu-container {
      overflow-x: scroll;
      display: flex;
      // flex-direction: row;
      align-items: center;
      height: 45px;
      width: 768px;
      padding: 0 15px;
      margin: 0 auto;
      white-space: nowrap;

      a {
        color: #42454c;
        font-weight: 500;
        font-size: 12px;
        margin-right: 30px;
        position: relative;

        &.active::before {
          content: "";
          // display: none;
          border-bottom: 3px solid #3f73f9;
          position: absolute;
          bottom: -5px;
          left: 0;
          width: 100%;
        }
      }

      a:last-child {
        margin-right: 15px;
      }
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #f1f1f1;
    }
  }
}
