@media only screen and (min-width : 1000px) {
  .Category {
    display: flex;
    min-width: 87px;
    width: 200px;

    .Line {
      margin-right: 10px;
    }

    .Checkbox {
      align-items: flex-start;
      margin-right: 10px;
    }

    .main {
      display: flex;
      flex-direction: column;

      .type {
        font-weight: 600;
        font-size: 12px;
      }

      .amount {
        margin-top: 10px;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
}

@media only screen and (max-width : 767px) {
  .Category {
    display: flex;
    min-width: 60px;
    font-size: 12px;

    .Line {
      margin-left: 100px;
    }

    .Checkbox {
      align-items: flex-start;
      margin-right: 10px;
    }

    .main {
      display: flex;
      flex-direction: column;

      .type {
        font-weight: normal;
        font-size: 12px;
      }

      .amount {
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}

@media only screen and (min-width : 768px) and (max-width: 1000px) {
  .Category {
    display: flex;
    min-width: 87px;
    font-size: 12px;

    .Line {
      margin-right: 10px;
    }

    .Checkbox {
      align-items: flex-start;
      margin-right: 10px;
    }

    .main {
      display: flex;
      flex-direction: column;

      .type {
        font-weight: normal;
        font-size: 12px;
      }

      .amount {
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}
