.Audience {
	.container {
		padding: 0 40px;
	}

	.title {
		font-weight: bold;
		margin-bottom: 10px;
	}

	.sub-title {
		font-weight: normal;
		opacity: 0.6;
	}

	.add-segment {
		display: flex;
		justify-content: center;
		margin: 40px 0;

		.blue {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	.search-audience {
		display: flex;
		justify-content: center;
		margin-top: 10px;

		span {
			color: #3f73f9;
			margin-left: 10px;
		}
	}

	.rows {
		.row {
			margin-top: 40px;

			.items {
				margin-top: 20px;
				display: flex;
				align-items: center;

				.Age {
					margin: 0 20px;
				}

				.IncomeLevel {
					margin-right: 20px;
				}

				.crm {
					background: #f0f1f3;
					box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
						0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
					border-radius: 4px;
					width: 100%;
				}

				.FileUpload {
					width: 100%;
				}
			}
		}

		.row-wrapp {
			display: flex;
			align-items: center;

			.row {
				width: 50%;

				&:first-child {
					margin-right: 20px;
				}
			}

			&:last-child {
				.row {
					margin-top: 20px;
				}
			}
		}
	}

	.actions {
		display: flex;
		align-items: center;
		margin-top: 40px;
		height: 72px;
		background: #f9f9f9;
		padding: 0 40px;
		border-radius: 0 0 24px 24px;

		.wrapp {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex: 1;

			.secondary {
				margin-right: 20px;
			}
		}
	}
}
