@media only screen and (min-width : 1000px) {
  .Table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    .cell {
      font-size: 10px;
      margin: 0;
      display: flex;
      align-items: center;
      width: 1%;
      /* The secret sauce each cell should grow equally */

      &.collapse {
        width: 0.0000000001%;
        /* But "collapsed" cells should be as small as possible */
      }
    }

    .heading {
      margin-bottom: 10px;

      .cell {
        font-weight: 300;
        font-size: 12px;
        user-select: none;
        justify-content: flex-start;
        padding: 0 20px;

        .text {
          color: #42454c;
          opacity: 0.6;
          margin-right: 5px;
        }

        &.isGrouped {
          .GroupIcon {
            transform: rotate(-90deg);
          }
        }
      }

      .GroupBy {
        margin-right: 6px;
        padding: 0 4px;
      }
    }

    .body {
      .row {
        background: #fff;
        height: 85px;
        margin-bottom: 10px;
        padding: 20px 10px 20px 0;
        border-radius: 16px;

        .cell {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-weight: 500;
          font-size: 14px;
          border-right: 1px solid #3f73f9;
          padding: 0 20px;

          .expanded {
            display: flex;
            align-items: center;

            svg {
              transform: rotate(180deg);
              margin-right: 5px;
              width: 4px;
              height: 12px;
              margin-right: 10px;
            }

            &.isExpanded {
              svg {
                transform: rotate(-90deg);
              }
            }
          }

          .sub-rows-length {
            margin-left: 8px;
          }
        }
      }
    }
  }
}

@media screen and (max-width : 375px) {
  .Replenishment {
    .Table {
      border: 0 !important;

      .body {
        width: 270px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .row {
          margin-bottom: 20px !important;
          display: flex !important;
          width: 261px !important;
          height: 269px !important;
          padding: 5.038px 13.434px !important;
          flex-direction: column !important;
          justify-content: center !important;
          align-items: flex-start !important;
          flex-shrink: 0 !important;
          border-radius: 4px !important;
          background: #FFF !important;
          box-shadow: 0px 0px 0.8396501541137695px 0px rgba(0, 0, 0, 0.04), 0px 1.679300308227539px 5.037900924682617px 0px rgba(0, 0, 0, 0.04), 0px 13.434402465820312px 20.15160369873047px 0px rgba(0, 0, 0, 0.06);

          .cell {
            padding: 0;
            padding-top: 10px;
            border-right: 0 !important;
            display: block !important;
            font-size: 13px;
            border-bottom: 1px solid #ccc;
            width: 235px !important;
            height: auto !important;
            min-height: 40px !important;
            color: var(--colors-42454-c, #42454C);
            font-size: 11.755px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            flex: none !important;
          }

          [data-label="Статус"] {
            border-bottom: 0;
          }

          .cell:before {
            content: attr(data-label);
            float: left;
            color: var(--colors-42454-c, #42454C);
            font-size: 10.076px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-transform: capitalize;
            opacity: 0.6;
            width: 75px;
            padding-bottom: 10px;
          }
        }
      }

      .heading {
        display: none !important;
      }
    }
  }

  .CountersTable {
    .Table {
      /* Make sure the inner table is always as wide as needed */
      width: 288px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      // border-spacing: 0;

      .cell {
        font-size: 10px;
        margin: 0;
        display: flex;
        align-items: center;
        width: 1%;
        /* The secret sauce each cell should grow equally */

        &.collapse {
          width: 0.0000000001%;
          /* But "collapsed" cells should be as small as possible */
        }
      }

      .heading {
        margin-bottom: 10px;


        .cell {
          font-size: 12px;
          user-select: none;
          justify-content: flex-start;
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          .text {
            color: #42454c;
            opacity: 0.6;
            margin-right: 5px;
          }

          &.isGrouped {
            .GroupIcon {
              transform: rotate(-90deg);
            }
          }
        }

        .GroupBy {
          margin-right: 6px;
          padding: 0 4px;
        }
      }

      .body {
        .row {
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
            0px 0px 1px rgba(0, 0, 0, 0.04);
          height: 110px;
          width: 288px;
          margin-bottom: 10px;
          padding: 10px 5px 10px 5px;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          .cell {
            border-radius: 2px;
            font-weight: 300;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: 500;
            font-size: 12px;
            padding: 0 20px;

            .expanded {
              display: flex;
              align-items: center;

              svg {
                transform: rotate(180deg);
                margin-right: 5px;
                width: 4px;
                height: 12px;
                margin-right: 10px;
              }

              &.isExpanded {
                svg {
                  transform: rotate(-90deg);
                }
              }
            }

            .sub-rows-length {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }

  .BalanceHistory {
    .Table {
      border: 0 !important;

      .body {
        width: 270px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .row {
          margin-bottom: 20px !important;
          display: flex !important;
          width: 261px !important;
          height: 174px !important;
          padding: 5.038px 13.434px !important;
          flex-direction: column !important;
          justify-content: center !important;
          align-items: flex-start !important;
          flex-shrink: 0 !important;
          border-radius: 4px !important;
          background: #FFF !important;
          box-shadow: 0px 0px 0.8396501541137695px 0px rgba(0, 0, 0, 0.04), 0px 1.679300308227539px 5.037900924682617px 0px rgba(0, 0, 0, 0.04), 0px 13.434402465820312px 20.15160369873047px 0px rgba(0, 0, 0, 0.06);

          .cell {
            padding: 0;
            padding-top: 10px;
            border-right: 0 !important;
            display: block !important;
            font-size: 13px;
            border-bottom: 1px solid #ccc;
            width: 235px !important;
            height: auto !important;
            min-height: 40px !important;
            color: var(--colors-42454-c, #42454C);
            font-size: 11.755px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            flex: none !important;
          }

          [data-label="Описание"] {
            border-bottom: 0;
          }

          .cell:before {
            content: attr(data-label);
            float: left;
            color: var(--colors-42454-c, #42454C);
            font-size: 10.076px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-transform: capitalize;
            opacity: 0.6;
            width: 75px;
            padding-bottom: 10px;
          }
        }
      }

      .heading {
        display: none !important;
      }
    }
  }

  .CountersTable {
    .Table {
      /* Make sure the inner table is always as wide as needed */
      width: 288px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      // border-spacing: 0;

      .cell {
        font-size: 10px;
        margin: 0;
        display: flex;
        align-items: center;
        width: 1%;
        /* The secret sauce each cell should grow equally */

        &.collapse {
          width: 0.0000000001%;
          /* But "collapsed" cells should be as small as possible */
        }
      }

      .heading {
        margin-bottom: 10px;


        .cell {
          font-size: 12px;
          user-select: none;
          justify-content: flex-start;
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          .text {
            color: #42454c;
            opacity: 0.6;
            margin-right: 5px;
          }

          &.isGrouped {
            .GroupIcon {
              transform: rotate(-90deg);
            }
          }
        }

        .GroupBy {
          margin-right: 6px;
          padding: 0 4px;
        }
      }

      .body {
        .row {
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
            0px 0px 1px rgba(0, 0, 0, 0.04);
          height: 110px;
          width: 288px;
          margin-bottom: 10px;
          padding: 10px 5px 10px 5px;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          .cell {
            border-radius: 2px;
            font-weight: 300;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: 500;
            font-size: 12px;
            padding: 0 20px;

            .expanded {
              display: flex;
              align-items: center;

              svg {
                transform: rotate(180deg);
                margin-right: 5px;
                width: 4px;
                height: 12px;
                margin-right: 10px;
              }

              &.isExpanded {
                svg {
                  transform: rotate(-90deg);
                }
              }
            }

            .sub-rows-length {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width:375px) and (max-width : 767px) {
  .Replenishment {
    .Table {
      border: 0 !important;

      .body {
        width: 343px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .row {
          margin-bottom: 20px !important;
          display: flex !important;
          width: 311px !important;
          height: 323px !important;
          padding: 5.038px 13.434px !important;
          flex-direction: column !important;
          justify-content: center !important;
          align-items: flex-start !important;
          flex-shrink: 0 !important;
          border-radius: 4px !important;
          background: #FFF !important;
          box-shadow: 0px 0px 0.8396501541137695px 0px rgba(0, 0, 0, 0.04), 0px 1.679300308227539px 5.037900924682617px 0px rgba(0, 0, 0, 0.04), 0px 13.434402465820312px 20.15160369873047px 0px rgba(0, 0, 0, 0.06);

          .cell {
            padding: 0;
            padding-top: 10px;
            border-right: 0 !important;
            display: block !important;
            font-size: 13px;
            border-bottom: 1px solid #ccc;
            width: 279px !important;
            height: auto !important;
            min-height: 40px !important;
            color: var(--colors-42454-c, #42454C);
            font-size: 11.755px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            flex: none !important;
          }

          [data-label="Статус"] {
            border-bottom: 0;
          }

          .cell:before {
            content: attr(data-label);
            float: left;
            color: var(--colors-42454-c, #42454C);
            font-size: 10.076px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-transform: capitalize;
            opacity: 0.6;
            width: 75px;
            margin-right: 50px;
            padding-bottom: 10px;
          }
        }
      }

      .heading {
        display: none !important;
      }
    }
  }

  .CountersTable {
    .Table {
      /* Make sure the inner table is always as wide as needed */
      width: 343px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      // border-spacing: 0;

      .cell {
        font-size: 10px;
        margin: 0;
        display: flex;
        align-items: center;
        width: 1%;
        /* The secret sauce each cell should grow equally */

        &.collapse {
          width: 0.0000000001%;
          /* But "collapsed" cells should be as small as possible */
        }
      }

      .heading {
        margin-bottom: 10px;


        .cell {
          font-size: 12px;
          user-select: none;
          justify-content: flex-start;
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          .text {
            color: #42454c;
            opacity: 0.6;
            margin-right: 5px;
          }

          &.isGrouped {
            .GroupIcon {
              transform: rotate(-90deg);
            }
          }
        }

        .GroupBy {
          margin-right: 6px;
          padding: 0 4px;
        }
      }

      .body {
        .row {
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
            0px 0px 1px rgba(0, 0, 0, 0.04);
          height: 110px;
          width: 343px;
          margin-bottom: 10px;
          padding: 10px 5px 10px 5px;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          .cell {
            border-radius: 2px;
            font-weight: 300;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: 500;
            font-size: 12px;
            padding: 0 20px;

            .expanded {
              display: flex;
              align-items: center;

              svg {
                transform: rotate(180deg);
                margin-right: 5px;
                width: 4px;
                height: 12px;
                margin-right: 10px;
              }

              &.isExpanded {
                svg {
                  transform: rotate(-90deg);
                }
              }
            }

            .sub-rows-length {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }

  .BalanceHistory {
    .Table {
      border: 0 !important;

      .body {
        width: 343px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .row {
          margin-bottom: 20px !important;
          display: flex !important;
          width: 311px !important;
          height: 174px !important;
          padding: 5.038px 13.434px !important;
          flex-direction: column !important;
          justify-content: center !important;
          align-items: flex-start !important;
          flex-shrink: 0 !important;
          border-radius: 4px !important;
          background: #FFF !important;
          box-shadow: 0px 0px 0.8396501541137695px 0px rgba(0, 0, 0, 0.04), 0px 1.679300308227539px 5.037900924682617px 0px rgba(0, 0, 0, 0.04), 0px 13.434402465820312px 20.15160369873047px 0px rgba(0, 0, 0, 0.06);

          .cell {
            padding: 0;
            padding-top: 10px;
            border-right: 0 !important;
            display: block !important;
            font-size: 13px;
            border-bottom: 1px solid #ccc;
            width: 279px !important;
            height: auto !important;
            min-height: 40px !important;
            color: var(--colors-42454-c, #42454C);
            font-size: 11.755px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            flex: none !important;
          }

          [data-label="Описание"] {
            border-bottom: 0;
          }

          .cell:before {
            content: attr(data-label);
            float: left;
            color: var(--colors-42454-c, #42454C);
            font-size: 10.076px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-transform: capitalize;
            opacity: 0.6;
            width: 75px;
            margin-right: 50px;
            padding-bottom: 10px;
          }
        }
      }

      .heading {
        display: none !important;
      }
    }
  }

  .CountersTable {
    .Table {
      /* Make sure the inner table is always as wide as needed */
      width: 343px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      // border-spacing: 0;

      .cell {
        font-size: 10px;
        margin: 0;
        display: flex;
        align-items: center;
        width: 1%;
        /* The secret sauce each cell should grow equally */

        &.collapse {
          width: 0.0000000001%;
          /* But "collapsed" cells should be as small as possible */
        }
      }

      .heading {
        margin-bottom: 10px;


        .cell {
          font-size: 12px;
          user-select: none;
          justify-content: flex-start;
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          .text {
            color: #42454c;
            opacity: 0.6;
            margin-right: 5px;
          }

          &.isGrouped {
            .GroupIcon {
              transform: rotate(-90deg);
            }
          }
        }

        .GroupBy {
          margin-right: 6px;
          padding: 0 4px;
        }
      }

      .body {
        .row {
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
            0px 0px 1px rgba(0, 0, 0, 0.04);
          height: 110px;
          width: 343px;
          margin-bottom: 10px;
          padding: 10px 5px 10px 5px;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          .cell {
            border-radius: 2px;
            font-weight: 300;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: 500;
            font-size: 12px;
            padding: 0 20px;

            .expanded {
              display: flex;
              align-items: center;

              svg {
                transform: rotate(180deg);
                margin-right: 5px;
                width: 4px;
                height: 12px;
                margin-right: 10px;
              }

              &.isExpanded {
                svg {
                  transform: rotate(-90deg);
                }
              }
            }

            .sub-rows-length {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width:768px) and (max-width : 1000px) {
  .Replenishment {
    .Table {
      .heading {
        margin-bottom: 10px;

        .row {

          .cell:nth-child(1) {
            display: flex;
            flex-direction: row;
            width: 125px !important;
          }

          .cell:nth-child(2) {
            width: 300px !important;
          }

          .cell:nth-child(3) {
            padding: 0;
            width: 105px;
          }

          .cell:nth-child(4) {
            padding: 0;
          }

          .cell:nth-child(5) {
            display: none !important;
          }

          .cell:nth-child(6) {
            display: none !important;
          }
        }

        .cell {
          font-weight: 300;
          font-size: 12px;
          user-select: none;
          justify-content: flex-start;
          padding: 0 20px;

          .text {
            color: #42454c;
            opacity: 0.6;
            margin-right: 5px;
          }

          &.isGrouped {
            .GroupIcon {
              transform: rotate(-90deg);
            }
          }
        }

        .GroupBy {
          margin-right: 6px;
          padding: 0 4px;
        }
      }

      .body {
        .row {
          background: #fff;
          width: 634px;
          margin-bottom: 10px;
          padding: 24px 10px 14px 0;
          border-radius: 16px;
          flex-wrap: wrap;

          .cell:nth-child(5) {
            border-right: 0;
            margin-top: 10px;
            width: 165px !important;
            flex: none !important;
            flex-direction: row !important;
          }

          .cell:nth-child(4) {
            border-right: 0;
          }

          .cell:nth-child(6) {
            margin-top: 10px;
            width: 165px !important;
            flex: none !important;
            flex-direction: row !important;
          }

          [data-label="Операция"] {
            width: 250px !important;
          }
          [data-label="Сумма без НДС"] {
            width: 75px !important;
          }
          [data-label="Сумма с НДС"] {
            width: 75px !important;
          }

          [data-label="Документы"]:before {
            content: attr(data-label);
            float: left;
            color: #42454C;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-transform: capitalize;
            opacity: 0.6;
            width: 75px;
          }

          [data-label="Статус"]:before {
            content: attr(data-label);
            float: left;
            color: #42454C;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-transform: capitalize;
            opacity: 0.6;
            width: 75px;
          }

          .cell {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            height: 30px;
            border-right: 1px solid #3f73f9;
            padding: 0 20px;

            .expanded {
              display: flex;
              align-items: center;

              svg {
                transform: rotate(180deg);
                margin-right: 5px;
                width: 4px;
                height: 12px;
                margin-right: 10px;
              }

              &.isExpanded {
                svg {
                  transform: rotate(-90deg);
                }
              }
            }

            .sub-rows-length {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }

  .BalanceHistory {
    .Table {
      border: 0 !important;

      .heading {
        margin-bottom: 10px;

        .cell {
          font-weight: 300;
          font-size: 12px;
          user-select: none;
          justify-content: flex-start;
          padding: 0 20px;
          display: flex;
          flex-direction: row;

          .text {
            color: #42454c;
            opacity: 0.6;
            margin-right: 5px;
          }

          &.isGrouped {
            .GroupIcon {
              transform: rotate(-90deg);
            }
          }
        }

        .GroupBy {
          margin-right: 6px;
          padding: 0 4px;
        }
      }

      .body {
        width: 634px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .row {
          background: #fff;
          width: 634px;
          margin-bottom: 10px;
          padding: 24px 10px 24px 0;
          border-radius: 16px;

          .cell {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            height: 30px;
            border-right: 1px solid #3f73f9;
            padding: 0 20px;

            .expanded {
              display: flex;
              align-items: center;

              svg {
                transform: rotate(180deg);
                margin-right: 5px;
                width: 4px;
                height: 12px;
                margin-right: 10px;
              }

              &.isExpanded {
                svg {
                  transform: rotate(-90deg);
                }
              }
            }

            .sub-rows-length {
              margin-left: 8px;
            }
          }
        }
      }

    }
  }

  .CountersTable {
    .Table {
      /* Make sure the inner table is always as wide as needed */
      width: 100%;
      border-spacing: 0;
  
      .cell {
        font-size: 10px;
        margin: 0;
        display: flex;
        align-items: center;
        width: 1%;
        /* The secret sauce each cell should grow equally */
  
        &.collapse {
          width: 0.0000000001%;
          /* But "collapsed" cells should be as small as possible */
        }
      }
  
      .heading {
        margin-bottom: 10px;
  
        .cell {
          font-weight: 300;
          font-size: 12px;
          user-select: none;
          justify-content: flex-start;
          padding: 0 20px;
  
          .text {
            color: #42454c;
            opacity: 0.6;
            margin-right: 5px;
          }
  
          &.isGrouped {
            .GroupIcon {
              transform: rotate(-90deg);
            }
          }
        }
  
        .GroupBy {
          margin-right: 6px;
          padding: 0 4px;
        }
      }
  
      .body {
        .row {
          background: #fff;
          height: 85px;
          margin-bottom: 10px;
          padding: 20px 10px 20px 0;
          border-radius: 16px;
  
          .cell {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            border-right: 1px solid #3f73f9;
            padding: 0 20px;
  
            .expanded {
              display: flex;
              align-items: center;
  
              svg {
                transform: rotate(180deg);
                margin-right: 5px;
                width: 4px;
                height: 12px;
                margin-right: 10px;
              }
  
              &.isExpanded {
                svg {
                  transform: rotate(-90deg);
                }
              }
            }
  
            .sub-rows-length {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}