@media only screen and (max-width : 375px) {
  .PasswordRecovery {
    display: flex;
    background: #f0f1f3;
    // position: absolute;
    flex-direction: column;
    // flex-wrap: wrap;
    align-items: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    height: 100vh;
    font-family: Raleway;

    .images {
      background-image: url("../../assets/images/bg-auth.png");
      position: relative;
      background-repeat: no-repeat;
      border-radius: 10px;
      width: 288px;
      height: 148px;
      // padding-left: 47px;
      padding-top: 57px;
      margin-bottom: 550px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 21px;
      left: 0;
      right: 0;
      text-align: center;
      .greetings {
        display: none;
        &-title {
          display: none;
        }
      }
    }

    .wrapp {
      position: absolute;
      width : 288px;
      background: #f0f1f3;
      max-height: 255px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 183px;

      .form {
        display: flex;
        flex-direction: column;
        gap: 0px;
        // padding-bottom: 380px;
        margin: auto auto;
        width: 288px;

        &-title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .sub-title {
          opacity: 0.6;
          font-weight: normal;
          margin-bottom: 10px;
        }

        .email-field {
          width: 100%;
          // margin-bottom: 5px;
          &.err {
            border-color: var(--error, #FF0D00);
            ;
          }
        }

        .validate_input {
          display: flex;
          flex-direction: column;

          .err {
            border: 1px solid;
            border-color: var(--error, #FF0D00);
          }
        }

        .error_text {
          margin-left: 10px;
          display: flex;
          width: 451px;
          height: 18px;
          flex-direction: column;
          justify-content: center;
          color: var(--error, #FF0D00);
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .helpfullText {
          color: #42454C;
          opacity: 50%;
          font-size: 10px;
          margin-left: 12px;
          width: 424px;
          position: relative;
          bottom: 8px;
        }

        .btn-actions {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .Button {
            width: 100%;
          }

          .primary {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width : 375px) and (max-width: 767px) {
  .PasswordRecovery {
    display: flex;
    background: #f0f1f3 !important;
    align-items: center;
    align-content: center;
    // margin-bottom: 500px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    overflow: auto;
    font-family: Raleway;

    .images {
      background-image: url("../../assets/images/bg-auth.png");
      position: absolute;
      background-repeat: no-repeat;
      border-radius: 10px;
      width: 343px;
      height: 176px;
      padding-top: 67px;
      margin-top: 10px;
      // margin-bottom: 550px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 0px;
      text-align: center;
      .greetings {
        display: none;
        &-title {
          display: none;
        }
      }
    }

    .wrapp {
      position: absolute;
      width: 343px;
      max-height: 250px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 0px;

      .form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-top: 200px;
        margin: auto auto;
        width: 343px;

        &-title {
          font-size: 18px;
          font-weight: bold;
          // margin-bottom: 10px;
        }

        .sub-title {
          opacity: 0.6;
          font-weight: normal;
          margin-bottom: -10px;
        }

        
        .email-field {
          width: 100%;
          // margin-bottom: 5px;
          &.err {
            border-color: var(--error, #FF0D00);
            ;
          }
        }

        .validate_input {
          display: flex;
          flex-direction: column;

          .err {
            border: 1px solid;
            border-color: var(--error, #FF0D00);
          }
        }

        .error_text {
          margin-left: 10px;
          display: flex;
          width: 451px;
          height: 18px;
          flex-direction: column;
          justify-content: center;
          color: var(--error, #FF0D00);
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .helpfullText {
          color: #42454C;
          opacity: 50%;
          font-size: 10px;
          margin-left: 12px;
          width: 424px;
          position: relative;
          bottom: 8px;
        }


        .btn-actions {
          // margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .Button {
            width: 100%;
          }

          .primary {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width : 768px) and (max-width: 1000px) {
  .PasswordRecovery {
    display: flex;
    background: #f0f1f3 !important;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    max-height: 100vh;
    overflow: auto;
    font-family: Raleway;

    .images {
      background-image: url("../../assets/images/bg-auth.png");
      position: absolute;
      top: 0px;
      background-repeat: no-repeat;
      border-radius: 10px;
      width : 556px;
      height: 202px;
      // padding-left: 47px;
      margin-top: 10px;
      padding-top: 77px;
      // margin-bottom: 800px; //790px
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      .greetings {
        display: none;
        &-title {
          display: none;
        }
      }
    }

    .wrapp {
      position: absolute;
      width : 460px;
      max-height: 401px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      margin-top: 30px;
      // top: 330px;//300px;
      padding-top: -10px;
      top: 200px;

      .form {
        width: 460px;

        &-title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .sub-title {
          opacity: 0.6;
          font-weight: normal;
          margin-bottom: 10px;
        }

        .email-field {
          width: 100%;
          // margin-bottom: 5px;
          &.err {
            border-color: var(--error, #FF0D00);
            ;
          }
        }

        .validate_input {
          display: flex;
          flex-direction: column;

          .err {
            border: 1px solid;
            border-color: var(--error, #FF0D00);
          }
        }

        .error_text {
          margin-left: 10px;
          display: flex;
          width: 451px;
          height: 18px;
          flex-direction: column;
          justify-content: center;
          color: var(--error, #FF0D00);
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .helpfullText {
          color: #42454C;
          opacity: 50%;
          font-size: 10px;
          margin-left: 12px;
          width: 424px;
          position: relative;
          bottom: 8px;
        }

        .btn-actions {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .Button {
            width: 100%;
          }

          .primary {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width : 1000px) {
  .PasswordRecovery {
    display: flex;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    width: 100vw;
    font-family: Raleway;

    .images {
      background-image: url("../../assets/images/bg-auth.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 50%;
      height: 100%;
      padding: 60px 40px;

      .greetings {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;

        &-title {
          font-weight: bold;
          font-size: 64px;
        }
      }
    }

    .wrapp {
      background: #f0f1f3;
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .form {
        width: 460px;

        &-title {
          font-size: 32px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .sub-title {
          opacity: 0.6;
          font-weight: normal;
          margin-bottom: 10px;
        }

        .email-field {
          width: 100%;
          // margin-bottom: 5px;
          &.err {
            border-color: var(--error, #FF0D00);
            ;
          }
        }

        .validate_input {
          display: flex;
          flex-direction: column;

          .err {
            border: 1px solid;
            border-color: var(--error, #FF0D00);
          }
        }

        .error_text {
          margin-left: 10px;
          display: flex;
          width: 451px;
          height: 18px;
          flex-direction: column;
          justify-content: center;
          color: var(--error, #FF0D00);
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .helpfullText {
          color: #42454C;
          opacity: 50%;
          font-size: 10px;
          margin-left: 12px;
          width: 424px;
          position: relative;
          bottom: 8px;
        }

        .btn-actions {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .Button {
            width: 100%;
          }

          .primary {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}