@function calc-between-width($width-start, $value-start, $width-end, $value-end) {
    @return calc(#{$value-start} * 1px + (#{$value-end} - #{$value-start}) * (100vw - #{$width-start} * 1px) / (#{$width-end} - #{$width-start}))
}

body {
    width: 100%;
    height: 100%;
    background: #E5E5E5;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;

    .Clients {
        padding-left: 30px;

        .downald {
            display: flex;
            align-items: center;
            width: 105px;
            height: 32px;
            border: 1px solid #3F73F9;
            filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
            border-radius: 4px;
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            justify-content: center;
            color: #3F73F9;
            margin-bottom: 25px;

            .btnText {
                font-family: 'Lato', sans-serif;
            }
        }

        .filters {
            display: flex;
            justify-content: row;
            flex-wrap: wrap;
            margin-bottom: 25px;
            margin-top: 50px;
            gap: 20px;
            width: calc-between-width(1600, 1275, 1920, 1400);

            input::-webkit-input-placeholder {
                color: rgba(0, 0, 0, 0.6);
                opacity: 0.8;
            }

            .MuiOutlinedInput-notchedOutline {
                border: 0
            }

            .searchId {
                width: 90px;
                height: 32px;
                background: #FFFFFF;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0;
                margin-top: 5px;
            }

            .searchEmail {
                width: 117px;
                height: 32px;
                background: #FFFFFF;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0;
                margin-top: 5px;
            }

            .searchPhone {
                width: 202px;
                height: 32px;
                background: #FFFFFF;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0;
                margin-top: 5px;
            }

            .searchTypeUser {
                width: 110px;
                height: 32px;
                background: #FFFFFF;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0;
                margin-top: 5px;
            }

            .searchInn {
                width: 55px;
                height: 32px;
                background: #FFFFFF;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0;
                margin-top: 5px;
            }

            .searchSite {
                width: 65px;
                height: 32px;
                background: #FFFFFF;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0;
                margin-top: 5px;
            }

            .searchStaff {
                width: 116px;
                height: 32px;
                background: #FFFFFF;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0;
                margin-top: 5px;
            }

            .searchDate {
                width: 460px;
                height: 32px;
                margin-right: 0;
                margin-top: 5px;

                .span {
                    position: absolute;
                    top: 55px;
                    text-align: center;
                    width: 460px;
                }
            }

            .searchRange {
                width: 263px;
                height: 32px;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-right: 0;
                position: relative;
                bottom: 14px;

                .inpRange {
                    display: flex;
                    flex-direction: row;

                    .start {
                        width: 130px;
                        margin-right: 3px;
                    }

                    .end {
                        width: 130px;
                    }
                }

                .span {
                    margin-top: 10px;
                    margin-bottom: 3px;
                    text-align: center;
                    width: 460px;
                }

            }

        }

        .table {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-bottom: 40px;
            //width: 79vw;

            .container {
                height: 51.8vh;
                width: 2105px;
                overflow: auto;

                .row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 40px;
                    width: 1500px;
                    background: #FFFFFF;
                    box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.04);
                    border-radius: 9px;
                    margin-bottom: 10px;
                    width: 2100px;
                }
            }

            .tableHead {
                font-family: 'Lato', sans-serif;
                font-style: normal;
                font-size: 12px;
                line-height: 14px;
                display: flex;
                align-items: center;
                color: #42454C;
                width: 1470px;
                font-size: 14;
                width: 2065px;


                .tr {
                    width: 200px;
                    display: flex;
                    justify-content: center;
                }
            }

            .check {
                width: 1px;
                height: 20px;
                background: #3F73F9;
                border-radius: 1px;
                transform: matrix(-1, 0, 0, 1, 0, 0);
            }
        }
    }
}