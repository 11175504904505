.Balance {
	.h1 {
		margin-bottom: 40px;
	}

	.CardWhite {
		padding: 60px;

		.h1,
		.h2 {
			font-weight: 700;
		}

		.span2 {
			padding-left: 13px;
			margin-top: -13px;
		}

		// input {
		// 	margin-top: 20px;
		// 	width: 415px;
		// 	background: #f0f1f3;
		// }
		.head {
			display: flex;
			flex-direction: row;

			.ya_account {
				position: relative;
				font-size: 16px;
				// margin-top: 4px;
				margin-bottom: 37px;
				margin-left: 6px;
				display: flex;
				flex-direction: row;
				align-items: center;
			}

			.helpText {
				text-align: start;
				box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
				border-radius: 4px;
				background: #FFFFFF;
				font-size: 14px;
				display: flex;
				flex-direction: column;
				gap: 20px;
				width: 340px;
				padding: 20px;
				color: #000000;
				z-index: 10000;
				position: absolute;
				margin-left: 30px;

				// top: 35px;
				// right: calc-between-width(1600, -20, 1920, -62);
				.title {
					display: flex;
					flex-direction: row;
					align-items: center;
				}
			}
		}


		.actions {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex: 1;
			margin-top: 60px;

			.primary {
				width: 210px;
			}
		}
	}
}

@media screen and (max-width : 375px) {
	.Balance {
		.Typography {
			margin-top: 25px;
			margin-bottom: 10px;
			color: var(--colors-42454-c, #42454C);
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}

		.head {
			display: flex;
			height: 45px;
			padding: 10.076px 13.434px;
			align-items: center;
			gap: 8.397px;
			white-space: nowrap;

			.ya_account {
				margin-bottom: 0;
			}
		}


		.h1 {
			margin-bottom: 0;

			&.title {
				margin-top: 25px;
				margin-bottom: 10px;
			}
		}

		.CardWhite {
			padding: 20.152px 13.434px;
			width: 288px;
			display: inline-block;
			padding: 0;
			border-radius: 4px;
			border: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
			background: var(--colors-f-9-f-9-f-9, #F9F9F9);

			.head {
				display: flex;
				height: 45px;
				padding: 10.076px 13.434px;
				align-items: center;
				gap: 8.397px;
				white-space: nowrap;

				.ya_account {
					margin-bottom: 0;
				}

				.helpText {
					white-space: break-spaces;
					width: 261px;
					margin-left: -145px;
				}
			}

		}

		.headText {
			margin-top: 0;
			color: var(--colors-42454-c, #42454C);
			text-align: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			text-transform: capitalize;
		}

		.cardBody {
			width: 288px;
			height: 163px;
			padding: 0 20.152px 13.434px;
			display: block;
			border-top: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));

			.css-i44wyl {
				margin-top: 0 !important;
				width: 248px;
			}

			.headText {
				color: var(--colors-42454-c, #42454C);
				text-align: start;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				text-transform: capitalize;
				margin-top: 20px;
			}

			.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
				margin-top: 8px !important;
			}

			.text {
				padding: 0;
				color: var(--colors-42454-c-50, rgba(66, 69, 76, 0.50));
				text-align: start;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				opacity: 1;
			}

			.css-y62u9e-MuiFormControl-root-MuiTextField-root {
				width: 261px;
				height: 27px;
			}

			.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
				top: -11px;
				color: rgba(66, 69, 76, 0.50);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}

			.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
				color: rgba(66, 69, 76, 0.50);
				;
				top: -7px;
			}

			.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
				height: 27px;
				width: 248px;
				border-radius: 4px;
				background: #F0F1F3;
				box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

				input {
					font-size: 14px;
					padding: 0;
					padding-left: 10px;
					box-shadow: none;
				}

				fieldset {
					border: none;
				}
			}

			.css-1v4ccyo {
				height: 27px;
				width: 248px;
				border-radius: 4px;
				background: #F0F1F3;
				box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

				input {
					font-size: 14px;
					padding: 0;
					padding-left: 10px;
					box-shadow: none;
				}

				fieldset {
					border: none;
				}
			}

			.actions {
				margin-top: 10px;

				.Button {
					display: flex;
					width: 248px;
					height: 27px;
					padding: 7.283px 97.058px 5.717px 102.942px;
					justify-content: flex-end;
					align-items: center;
					border-radius: 4px;
					background: var(--colors-3-f-73-f-9, #3F73F9);
					flex-shrink: 0;
				}
			}
		}
	}
}

@media screen and (min-width:375px) and (max-width : 768px) {
	.Balance {
		.Typography {
			color: var(--colors-42454-c, #42454C);
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}

		.head {
			display: flex;
			height: 45px;
			padding: 10.076px 13.434px;
			align-items: center;
			gap: 8.397px;
			white-space: nowrap;

			.ya_account {
				margin-bottom: 0;
			}
		}


		.h1 {
			margin-bottom: 0;

			&.title {
				margin-top: 25px;
				margin-bottom: 10px;
			}
		}

		.CardWhite {
			padding: 20.152px 13.434px;
			width: 343px;
			display: inline-block;
			padding: 0;
			border-radius: 4px;
			border: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
			background: var(--colors-f-9-f-9-f-9, #F9F9F9);

			.head {
				display: flex;
				height: 45px;
				padding: 10.076px 13.434px;
				align-items: center;
				gap: 8.397px;
				white-space: nowrap;

				.ya_account {
					margin-bottom: 0;
				}

				.helpText {
					white-space: break-spaces;
					width: 300px;
					margin-left: -145px;
				}
			}

		}

		.headText {
			margin-top: 0;
			color: var(--colors-42454-c, #42454C);
			text-align: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			text-transform: capitalize;
		}

		.cardBody {
			width: 343px;
			height: 175px;
			padding: 0 20.152px 13.434px;
			display: block;
			border-top: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));

			.css-i44wyl {
				margin-top: 0 !important;
				width: 300px;
			}

			.headText {
				color: var(--colors-42454-c, #42454C);
				text-align: start;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				text-transform: capitalize;
				margin-top: 20px;
			}

			.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
				margin-top: 8px !important;
			}

			.text {
				padding: 0;
				color: var(--colors-42454-c-50, rgba(66, 69, 76, 0.50));
				text-align: start;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				opacity: 1;
			}

			.css-y62u9e-MuiFormControl-root-MuiTextField-root {
				width: 300px;
				height: 32px;
			}

			.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
				top: -11px;
				color: rgba(66, 69, 76, 0.50);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}

			.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
				color: rgba(66, 69, 76, 0.50);
				;
				top: -7px;
			}

			.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
				height: 32px;
				width: 300px;
				border-radius: 4px;
				background: #F0F1F3;
				box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

				input {
					font-size: 14px;
					padding: 0;
					padding-left: 10px;
					box-shadow: none;
				}

				fieldset {
					border: none;
				}
			}

			.css-1v4ccyo {
				height: 32px;
				width: 300px;
				border-radius: 4px;
				background: #F0F1F3;
				box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

				input {
					font-size: 14px;
					padding: 0;
					padding-left: 10px;
					box-shadow: none;
				}

				fieldset {
					border: none;
				}
			}

			.actions {
				margin-top: 10px;

				.Button {
					display: flex;
					width: 300px;
					height: 32px;
					padding: 7.283px 97.058px 5.717px 102.942px;
					justify-content: center;
					align-items: center;
					border-radius: 4px;
					background: var(--colors-3-f-73-f-9, #3F73F9);
					flex-shrink: 0;
				}
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width : 1000px) {
	.Balance {
		.Typography {
			color: var(--colors-42454-c, #42454C);
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}

		.head {
			display: flex;
			height: 45px;
			padding: 10.076px 13.434px;
			align-items: center;
			gap: 8.397px;
			white-space: nowrap;

			.ya_account {
				margin-bottom: 0;
			}
		}


		.h1 {
			margin-bottom: 0;

			&.title {
				font-size: 24px;
				margin-top: 25px;
				margin-bottom: 10px;
			}
		}

		.CardWhite {
			display: flex;
			flex-direction: column;
			width: 704px;
			padding: 36px;
			height: 268px;
			justify-content: center;
			align-items: flex-start;
			border-radius: 4px;
			border: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
			background: var(--colors-f-9-f-9-f-9, #F9F9F9);

			.head {
				display: flex;
				height: 45px;
				padding: 10.076px 13.434px;
				align-items: center;
				gap: 8.397px;
				white-space: nowrap;

				.ya_account {
					margin-bottom: 0;
					top: 2px;
				}

				.helpText {
					white-space: break-spaces;
					width: 300px;
					margin-left: 50px;
				}
			}

		}

		.headText {
			margin-top: 0;
			color: var(--colors-42454-c, #42454C);
			font-size: 24px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}

		.cardBody {
			width: 343px;
			height: 175px;
			padding: 0 20.152px 13.434px;
			display: block;
			//border-top: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));

			.css-i44wyl {
				margin-top: 0 !important;
				width: 300px;
			}

			.headText {
				color: var(--colors-42454-c, #42454C);
				text-align: start;
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				text-transform: capitalize;
				margin-top: 20px;
			}

			.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
				margin-top: 8px !important;
			}

			.text {
				padding: 0;
				color: var(--colors-42454-c-50, rgba(66, 69, 76, 0.50));
				text-align: start;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				opacity: 1;
			}

			.css-y62u9e-MuiFormControl-root-MuiTextField-root {
				width: 343px;
				height: 32px;
			}

			.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
				top: -11px;
				color: rgba(66, 69, 76, 0.50);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}

			.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
				color: rgba(66, 69, 76, 0.50);
				;
				top: -7px;
			}

			.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
				height: 32px;
				width: 343px;
				border-radius: 4px;
				background: #F0F1F3;
				box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

				input {
					font-size: 14px;
					padding: 0;
					padding-left: 10px;
					box-shadow: none;
				}

				fieldset {
					border: none;
				}
			}

			.css-1v4ccyo {
				height: 32px;
				width: 343px;
				border-radius: 4px;
				background: #F0F1F3;
				box-shadow: 0px 0px 0.8372092843055725px 0px rgba(0, 0, 0, 0.04), 0px 0px 1.674418568611145px 0px rgba(0, 0, 0, 0.06), 0px 3.34883713722229px 6.69767427444458px 0px rgba(0, 0, 0, 0.04);

				input {
					font-size: 14px;
					padding: 0;
					padding-left: 10px;
					box-shadow: none;
				}

				fieldset {
					border: none;
				}
			}

			.actions {
				margin-top: 24px;
				width: 632px;

				.Button {
					display: flex;
					width: 200px;
					height: 32px;
					padding: 7.283px 97.058px 5.717px 102.942px;
					justify-content: center;
					align-items: center;
					border-radius: 4px;
					background: var(--colors-3-f-73-f-9, #3F73F9);
					flex-shrink: 0;
					margin-right: 20px;
				}
			}
		}
	}
}