@media only screen and (min-width : 1000px) {
  .Statistics {
    .line {
      height: 2px;
      width: 100%;
      background-color: #42454c;
      opacity: 0.1;
      margin: 40px 0;
    }

    .container {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 40px;

      .CategoryStatistics {
        flex-direction: row;
        margin-right: 20px;
        width: 100%;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .Card {
      .CardDashedGraph {
        padding: 0;
      }
    }
  }
}

@media only screen and (max-width : 768px) {
  .Statistics {
    margin-top: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 0px;
    background: #fff;

    .line {
      height: 2px;
      width: 50%;
      background-color: #42454c;
      opacity: 0.1;
      margin: auto auto;
      margin-top: -10px;
    }
  
    .container {
      display: flex;
      width: 100%;
      align-items: center;
      // margin-bottom: 10px;
      margin-top: -10px;
      overflow-x: scroll;
      overflow-y: hidden;
      padding: 10px;
      background: #fff;
  
      .CategoryStatistics {
        background: #fff;
        flex-direction: row;
        margin-right: 5px;
        width: 100%;
  
        &:last-child {
          margin-right: 0;
        }
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #fff;
        width: 100%;
      }
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #fff;
      width: 100%;
    }
  
    .Card {
      margin-top: -20px;
      .CardDashedGraph {
        padding: 0;
      }
    }
  }
}

@media only screen and (min-width : 768px) and (max-width: 1000px) {
  .Statistics {
    margin-top: 10px;
    background-color: #f0f1f3;
    display: flex;
    flex-direction: column;
    gap: 0px;
    background: #f0f1f3;

    .line {
      display: none;
    }
  
    .container {
      display: flex;
      width: 100%;
      align-items: center;
      // margin-bottom: 10px;
      margin-top: -20px;
      overflow-x: scroll;
      overflow-y: hidden;
      padding: 10px;
      background: #f0f1f3;
  
      .CategoryStatistics {
        display: flex;
        flex-direction: row;
        margin-right: 5px;
        width: 100%;
        background: #f0f1f3;
  
        &:last-child {
          margin-right: 0;
        }
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #f0f1f3;
        width: 100%;
      }
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #f0f1f3;
      width: 100%;
    }
  
    .Card {
      margin-top: -20px;
      .CardDashedGraph {
        padding: 0;
      }
    }
  }
}

