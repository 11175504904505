.ReplenishmentForm {
  .CardWhite {
    padding: 40px;

    .rows {
      margin-top: 40px;

      .row {
        margin-bottom: 10px;

        .items {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-top: 20px;

          input {
            min-width: 305px;
            background: #f0f1f3;
            margin-right: 20px;
            margin-bottom: 20px;

            &:last-child {
              margin-right: 0;
            }
          }

          .Select {
            width: 305px;
            margin-right: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1;

      .primary {
        margin-left: 20px;
      }
    }
  }
}
