.BalanceHistory {

  .Sort {
    border-bottom: 2px solid rgba(66, 69, 76, 0.1);
    padding-bottom: 40px;
    margin: 40px 0 0 0;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 10px;

    .DatePicker {
      //margin-top: 20px;
      //margin-left: 20px;
      //margin-right: 20px;

      .date-picker {
        width: 260px;
        margin: 0;
      }
    }

  }

  .line {
    height: 2px;
    width: 100%;
    background-color: #42454c;
    opacity: 0.1;
    margin: 40px 0;
  }

  .BalanceHistoryTable {
    margin-top: 40px;
  }
}

@media screen and (max-width : 375px) {
  .BalanceHistory {
    display: flex;
    width: 288px;
    padding: 20.152px 13.434px;
    flex-direction: column;
    align-items: center;
    gap: 20.152px;
    border-radius: 0px 0px 4px 4px;
    border-right: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
    border-bottom: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
    border-left: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
    background: var(--colors-f-9-f-9-f-9, #F9F9F9);

    .line {
      display: none;
    }

    .BalanceHistoryTable {
      margin-top: 0;
    }

    .container {
      display: flex;
      flex-direction: column;

      .DatePicker {
        margin-left: 10px;

        .date-picker {
          width: 94px;
        }
      }

      .Select {
        &.white {
          max-width: none;
          min-width: 0px;
          width: 260px;

          .Dropdown {
            width: 260px;
          }
        }

        &.gray {
          max-width: none;
          min-width: 0px;
          width: 260px;

          .Dropdown {
            width: 260px;
          }
        }
      }
    }

    .css-ibnnmb {
      margin-left: 4px;
      margin-right: 4px;

      button {
        margin-left: 4px;

        svg {
          margin-left: 0;
          margin-right: 0;
        }
      }

      svg {
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
}

@media screen and (min-width:375px) and (max-width : 768px) {
  .BalanceHistory {
    display: flex;
    width: 343px;
    padding: 20.152px 13.434px;
    flex-direction: column;
    align-items: center;
    gap: 20.152px;
    border-radius: 0px 0px 4px 4px;
    border-right: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
    border-bottom: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
    border-left: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
    background: var(--colors-f-9-f-9-f-9, #F9F9F9);

    .line {
      display: none;
    }

    .BalanceHistoryTable {
      margin-top: 0;
    }

    .container {
      display: flex;
      flex-direction: column;

      .DatePicker {
        margin-left: 10px;

        .date-picker {
          width: 124px;
        }
      }

      .Select {
        &.white {
          max-width: none;
          min-width: 0px;
          width: 316px;
          height: 32px;

          .Dropdown {
            width: 316px;
          }
        }

        &.gray {
          max-width: none;
          min-width: 0px;
          width: 316px;
          height: 32px;

          .Dropdown {
            width: 316px;
          }
        }
      }
    }

    .css-ibnnmb {
      margin-left: 4px;
      margin-right: 4px;

      button {
        margin-left: 4px;

        svg {
          margin-left: 0;
          margin-right: 0;
        }
      }

      svg {
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
}

@media screen and (min-width:768px) and (max-width : 1000px) {
  .BalanceHistory {
    display: flex;
    width: 704px;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20.152px;
    border-radius: 0px 0px 4px 4px;
    border-right: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
    border-bottom: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
    border-left: 0.84px solid var(--colors-42454-c-10, rgba(66, 69, 76, 0.10));
    background: var(--colors-f-9-f-9-f-9, #F9F9F9);

    .line {
      display: none;
    }

    .BalanceHistoryTable {
      margin-top: 0;
    }

    .container {
      display: flex;
      flex-direction: column;

      .DatePicker {
        margin-left: 10px;

        .date-picker {
          width: 124px;
        }
      }

      .Select {
        &.white {
          max-width: none;
          min-width: 0px;
          width: 316px;
          height: 32px;

          .Dropdown {
            width: 316px;
          }
        }

        &.gray {
          max-width: none;
          min-width: 0px;
          width: 316px;
          height: 32px;

          .Dropdown {
            width: 316px;
          }
        }
      }
    }

    .css-ibnnmb {
      margin-left: 4px;
      margin-right: 4px;

      button {
        margin-left: 4px;

        svg {
          margin-left: 0;
          margin-right: 0;
        }
      }

      svg {
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
}