.BankAudits {
	.rows {
		margin-top: 40px;

		.row {
			margin-bottom: 10px;

			.items {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin-top: 20px;

				.input-data {
					min-width: 285px;
					background: #fff;
					margin-right: 20px;
					margin-bottom: 20px;

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}

	.actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex: 1;
		margin-top: 60px;

		.primary {
			width: 210px;
		}
	}
}
