@function calc-between-width(
  $width-start,
  $value-start,
  $width-end,
  $value-end
) {
  @return calc(
    #{$value-start} * 1px + (#{$value-end} - #{$value-start}) * (
        100vw - #{$width-start} * 1px
      ) / (#{$width-end} - #{$width-start})
  );
}
@media only screen and (min-width: 1000px) {
  .Counters {
    .Sort {
      border-bottom: 2px solid rgba(66, 69, 76, 0.1);
      padding-bottom: 40px;
      margin: 40px 0 0 0;
    }

    .search {
      margin-top: 28px;
      margin-bottom: 23px;
      width: 1380px;
      border-radius: 4px;
      background: #fff;
      .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
        border-color: rgba(66, 69, 76, 0.50);
      }
      .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
        border-color: rgba(66, 69, 76, 0.80);
      }
      .MuiInputBase-adornedStart {
        padding-left: 41px;
        .MuiInputBase-inputAdornedStart {
          padding-left: 5px;
          box-shadow: none;
        }
        fieldset {
          border-radius: 4px;
          border-width: 1px;
          border-color: rgba(66, 69, 76, 0.20);
          border-style: solid;
        }
      }
    }

    .add {
      width: 210px;
      margin: 40px 0;
    }

    &__dialog {
      padding: 40px;
    }

    .dialog__btn-submit {
      width: 210px;
    }

    .line {
      height: 2px;
      width: 100%;
      background-color: #42454c;
      opacity: 0.1;
      margin: 40px 0;
    }
    .div_buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .ya_account {
      position: relative;
      font-size: 16px;
      margin-right: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .helpText {
      text-align: start;
      box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
      border-radius: 4px;
      background: #ffffff;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 240px;
      padding: 20px;
      color: #000000;
      z-index: 10000;
      position: absolute;
      top: 35px;
      right: calc-between-width(1600, -20, 1920, -62);
      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .Table {
      .heading {
        padding-left: 33px;
        .cell {
          padding: 0;
          flex: none !important;
          .text {
            color: rgba(66, 69, 76, 0.7);
            font-family: Raleway;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }

      .body {
        .row:has(.active) {
          border-left: 5px solid #1ab21f;
        }
        .row:has(.not-active) {
          border-left: 5px solid #ff9200;
        }
        .row {
          background: #fff;
          border-radius: 5px;
          height: 60px;

          .cell:first-child {
            margin-left: 28px;
          }
          .cell {
            flex: none !important;
            border: none;
            padding: 0;
            color: #09203e;
            font-family: Raleway;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            text-transform: lowercase;
            .site {
              color: #3f73f9;
              font-family: Raleway;
              font-size: 14px;
              font-weight: 500;
            }
            .id {
              display: flex;
              align-items: center;
              gap: 21px;
              color: #09203e;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              text-transform: capitalize;
              svg {
                cursor: pointer;
              }
            }
            .status {
              display: flex;
              align-items: baseline;
              gap: 10px;
              font-weight: 700;
              text-transform: none;
              .not-active {
                color: #ff9200;
              }
              .active {
                color: #1ab21f;
                circle {
                  fill: #1ab21f;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .Counters {
    margin-top: 10px;
    .Sort {
      border-bottom: 2px solid rgba(66, 69, 76, 0.1);
      padding-bottom: 40px;
      margin: 40px 0 0 0;
    }

    .add {
      width: 288px;
      height: 32px;
      font-size: 12px;
      margin: 20px 0;
    }

    &__dialog {
      padding: 5px;
      font-size: 12px;
    }

    .dialog__btn-submit {
      width: 280px;
      font-size: 12px;
    }

    .line {
      display: none;
      height: 2px;
      width: 100%;
      background-color: #42454c;
      opacity: 0.1;
      margin: 40px 0;
    }
    .div_buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
    .ya_account {
      position: relative;
      font-size: 12px;
      margin-right: 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .helpText {
      text-align: start;
      box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
      border-radius: 4px;
      background: #ffffff;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 220px;
      padding: 10px;
      color: #000000;
      background: #ffffff;
      z-index: 10000;
      position: absolute;
      left: 50px;
      top: 35px;
      right: calc-between-width(1600, -20, 1920, -62);
      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

@media only screen and (min-width: 375px) and (max-width: 767px) {
  .Counters {
    margin-top: 10px;
    .Sort {
      border-bottom: 2px solid rgba(66, 69, 76, 0.1);
      padding-bottom: 40px;
      margin: 40px 0 0 0;
    }

    .add {
      width: 343px;
      height: 32px;
      font-size: 12px;
      margin: 20px 0;
    }

    &__dialog {
      padding: 5px;
      font-size: 12px;
    }

    .dialog__btn-submit {
      width: 343px;
      font-size: 12px;
    }

    .line {
      display: none;
      height: 2px;
      width: 100%;
      background-color: #42454c;
      opacity: 0.1;
      margin: 40px 0;
    }
    .div_buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
    .ya_account {
      position: relative;
      font-size: 12px;
      margin-right: 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .helpText {
      text-align: start;
      box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
      border-radius: 4px;
      background: #ffffff;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 220px;
      padding: 10px;
      color: #000000;
      background: #ffffff;
      z-index: 10000;
      position: absolute;
      left: 50px;
      top: 35px;
      right: calc-between-width(1600, -20, 1920, -62);
      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .Counters {
    margin-top: 10px;
    width: 704px;
    .Sort {
      border-bottom: 2px solid rgba(66, 69, 76, 0.1);
      padding-bottom: 40px;
      margin: 40px 0 0 0;
    }

    .add {
      width: 343px;
      height: 32px;
      font-size: 12px;
      margin: 20px 0;
    }

    &__dialog {
      padding: 5px;
      font-size: 12px;
    }

    .dialog__btn-submit {
      width: 343px;
      font-size: 12px;
    }

    .line {
      display: none;
      height: 2px;
      width: 100%;
      background-color: #42454c;
      opacity: 0.1;
      margin: 40px 0;
    }
    .div_buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .ya_account {
      position: relative;
      font-size: 12px;
      margin-right: 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .helpText {
      text-align: start;
      box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
      border-radius: 4px;
      background: #ffffff;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 220px;
      padding: 10px;
      color: #000000;
      background: #ffffff;
      z-index: 10000;
      position: absolute;
      margin-left: -90px;
      right: calc-between-width(1600, -20, 1920, -62);
      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}
