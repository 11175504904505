.IncomeLevel {
  width: 100%;

  .income-level__field {
    position: relative;

    input {
      width: 100%;
      padding: 8px 40px 8px 10px;
      background: #f0f1f3;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
        0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
      margin: 0;
    }

    .toggle-icon {
      position: absolute;
      right: 14px;
      top: 14px;

      &__open {
        transform: rotate(180deg);
        position: absolute;
        right: 14px;
        top: 14px;
      }
    }
  }
}

.Modal {
  .wrapper {
    .content {
      .income-level__visible {
        position: relative;

        input {
          width: 100%;
          padding: 8px 40px 8px 10px;
          background: #ffffff;
          border: 1px solid rgba(66, 69, 76, 0.2);
          box-sizing: border-box;
          border-radius: 4px;
          margin: 0;
        }

        .toggle-icon {
          position: absolute;
          right: 14px;
          top: 14px;

          &__open {
            transform: rotate(180deg);
            position: absolute;
            right: 14px;
            top: 14px;
          }
        }
      }

      .income-level__hidden {
        background: #ffffff;
        box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.11),
          0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
        padding: 10px;
        margin-top: 5px;

        .range-input {
          display: flex;
          align-items: baseline;
          justify-content: space-between;

          .field-form {
            margin: 0;
            width: 260px;
            background: #f0f1f3;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
              0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
          }
        }

        .eliminate-phrases {
          margin-top: 20px;

          input {
            background: #fff7f9;
            border: 1px solid #f93766;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
              0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            width: 100%;
            margin-bottom: 10px;
          }
        }

        .exceptions {
          font-weight: normal;
          opacity: 0.6;
        }

        .field-radio {
          margin: 20px 0;

          .RadioButton {
            margin-bottom: 5px;

            .radio {
              margin: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .btn-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;

        .Button {
          max-width: 210px;
        }

        .secondary {
          width: 210px;
        }

        .primary {
          margin-left: 20px;
        }
      }
    }
  }
}
