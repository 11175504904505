.reports {
    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
        width: 585px;
        background: #FFFFFF;
        box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.04);
        border-radius: 9px;
        margin-bottom: 10px;
        margin-top: 150px;
    }

    .td {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 17px;
        font-family: 'Lato', sans-serif;
        width: 200px
    }

    fieldset {
        border: none;
    }

    input {
        box-shadow: none;
        height: 7px;
        text-align: center;
    }

    .date {
        button {
            padding: 0;
            margin-right: 10px;
        }
    }

    // .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
    //     padding: 0;
    //     margin-right: 10px;
    // }

    .check {
        width: 1px;
        height: 20px;
        background: #3F73F9;
        border-radius: 1px;
        transform: matrix(-1, 0, 0, 1, 0, 0);
    }
}