@media only screen and (min-width : 768px) {
  .App {
      font-family: sans-serif;
      display: flex;
      flex-direction: column;
    }
    
  .search-container {
    width: 520px;
    display: flex;
    flex-direction: column;
  }

  .dropdown {
    background-color: white;
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
    padding: 5px;
  }

  .dropdown:empty {
    border: none;
  }

  .dropdown-row {
    cursor: pointer;
    text-align: start;
    margin: 2px 0;
  }

  input {
    height: 600;
    width: 520px;
    overflow: "auto";
  }

  .search-inner {
    display: flex;
    padding: 2px;
    border: 1px solid gray;
    border-radius: 3px;
    text-size-adjust: 15px;
  }

  .searchButton {
    min-width: 100px;
    height: 100;
    width: 100;
    overflow: "auto";
    background: #1976D2;
    color: black;
    display: flex;
    justify-content: center;
    font: 14px Arial;
    border-radius: 3px
  }
}

@media only screen and (max-width : 375px) {
  .App {
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }
  
.search-container {
  width: 225px;
  display: flex;
  flex-direction: column;
}

.dropdown {
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  padding: 5px;
}

.dropdown:empty {
  border: none;
}

.dropdown-row {
  cursor: pointer;
  text-align: start;
  margin: 2px 0;
}

input {
  height: 28px;
  width: 225px;
  overflow: "auto";
}

.search-inner {
  height: 32px;
  display: flex;
  padding: 2px;
  border: 1px solid gray;
  border-radius: 3px;
  text-size-adjust: 12px;
}

.searchButton {
  min-width: 100px;
  height: 100;
  width: 100;
  overflow: "auto";
  background: #1976D2;
  color: black;
  display: flex;
  justify-content: center;
  font: 12px Arial;
  border-radius: 3px
}
}
  