.PasswordResetConfirm {
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;

    .AdminLogo {
        display: flex;
        flex-direction: column;
        gap: 10px;
        line-height: 21px;
        font-size: 21px;
        background: #f0f1f3;
        height: 100vh;
        padding-top: 40px;
        padding-left: 40px;
        position: absolute;
        z-index: 0;
    }

    .wrapp {
        background: #f0f1f3;
        height: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .form {
            width: 460px;
            z-index: 1;

            &-title {
                font-size: 32px;
                font-weight: bold;
                margin-bottom: 10px;
            }

            .sub-title {
                opacity: 0.6;
                font-weight: normal;
                margin-bottom: 20px;
            }

            .helpfullText {
                color: #42454C;
                opacity: 50%;
                font-size: 10px;
                margin-left: 12px;
                width: 424px;
                position: relative;
                bottom: 8px;
            }

            .email-field,
            .password-field {
                width: 100%;
                margin-bottom: 5px;
            }

            .btn-actions {
                margin-top: 20px;

                .Button {
                    width: 100%;
                }

                .primary {
                    margin-bottom: 10px;
                }
            }
        }
    }
}